import api from './api';

function addCompetitionEntry(data) {
    return api
        .post(data.isMopupPhase ? 'entries/mop-up-phase' : 'entries/main-phase', data);
}

function getCompetitionEntry(entryPublicId) {
    return api
        .get(`/entries/${entryPublicId}`);
}

function getCompetitionEntries() {
    return api
        .get(`/entries`);
}

export default {
    addCompetitionEntry,
    getCompetitionEntry,
    getCompetitionEntries
};