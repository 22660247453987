import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './VoiceRecognition.scss';
import useEffectOnce from 'hooks/useEffectOnce';
import Button from 'components/Button/Button';
import Promo from 'components/Promotion/Promo';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';

import voiceBanner from 'assets/images/campaign/voiceRecofnition/voice-banner.png';
import voiceBanner2x from 'assets/images/campaign/voiceRecofnition/voice-banner@2x.png';
import voiceBannerMob from 'assets/images/campaign/voiceRecofnition/voice-banner-mob.png';
import voiceBannerMob2x from 'assets/images/campaign/voiceRecofnition/voice-banner-mob@2x.png';

import { ReactComponent as VoiceElipse } from 'assets/images/campaign/voiceRecofnition/elipse_canvas.svg';

import analyticsService from 'services/analyticsService';


export default (props) => {
  let history = useHistory();
  let { entryPublicId } = useParams();
  let competitionEntryState = useCompetitionEntryState();
  //const [entryPublicId, setEntryPublicId] = useState(props.entryPublicId);
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [templateStatus, setTemplateStatus] = useState("start-detection");
  const [startAnimation, setStartAnimation] = useState(false);
  const [finalTime, setFinalTime] = useState(false);
  
  //const [audioCtx, setAudioCtx] = useState(null);
  //const [stream, setStream] = useState(null);
  
  const audioRef = useRef(null);
  let loud_volume_threshold = 25;
  let seconds = 0;

  useEffect(() => {
    if(finalTime){
      handleAccessibleRoute(false);
    }
  }, [finalTime]);
  
  
  const getVoicePermission  = (e) => {
    if(!audioAllowed) {
      navigator.mediaDevices.getUserMedia({audio:true})
      .then((stream)=> {
        //setStream(stream);
        //setAudioAllowed(true);
        setTemplateStatus("voice-allowed");
        //setAudioCtx(new AudioContext());

        setTimeout(handleVoiceDetection(stream, true, new AudioContext()), 1000);

        competitionEntryState.setAudioUse(true, stream);


        analyticsService.logEvent("form", "VoiceInteraction", {
          action: "AllowedVoiceInteraction"
        });
        
      })
      .catch((e)=>{
        setAudioAllowed(false);
        competitionEntryState.setAudioUse(false, null);
      });
    }
  };

  const handleAccessibleRoute = (isAccessible = true) => {
    
    if(isAccessible){
      analyticsService.logEvent("form", "VoiceInteraction", {
        action: "OpenAccessibleRoute"
      });
    }

    history.push(`/thankyou/${entryPublicId}`);
    //history.push(`/thankyou`);
  }
  
  var handleVoiceDetection  = (stream, audioAllowed, audioCtx) => {
 
    var hasStopped = false;
    var hasStarted = false;

    if(startAnimation){
      setStartAnimation(false);
    } else {
        if(audioAllowed){
          
          var h = document.getElementsByTagName('h2')[0];
          var hSub = document.getElementsByTagName('h2')[1];

          var audioStream = audioCtx.createMediaStreamSource( stream );
          var analyser = audioCtx.createAnalyser();
          var fftSize = 1024;

          analyser.fftSize = fftSize;
          audioStream.connect(analyser);

          var bufferLength = analyser.frequencyBinCount;
          var frequencyArray = new Uint8Array(bufferLength);
          
          
          var showVolume = function () {
            setTimeout(showVolume, 500);

            analyser.getByteFrequencyData(frequencyArray);
            var total = 0
            for(var i = 0; i < 255; i++) {
                var x = frequencyArray[i];
                total += x * x;
            }
            var rms = Math.sqrt(total / bufferLength);
            var db = 20 * ( Math.log(rms) / Math.log(10) );
            db = Math.max(db, 0); // sanity check
            //h.innerHTML = Math.floor(db) + " dB";

            //if(Math.floor(db) > 25){
            if(Math.floor(db) > 25){
              

              if(!hasStarted){
                //console.log("animate");
                handleSVGAnimation("start");

                startInterval();
              }

              hasStarted = true;

              
            } else {
              handleSVGAnimation("stop");
              setFinalTime(false);
              hasStarted = false;
            }

            /*if (db >= loud_volume_threshold) {
                seconds += 0.5;
                if (seconds >= 5) {
                    hSub.innerHTML = "You've been in loud environment for<span> " + Math.floor(seconds) + " </span>seconds." ;
                }
            }
            else {
                seconds = 0;
                hSub.innerHTML = "";
            }*/
          }

          function startInterval() {
            var timeleft = 5;
            var downloadTimer = setInterval(function(){
              timeleft--;
              
              if(timeleft <= 0){
                clearInterval(downloadTimer);
                setFinalTime(true);
              }
            },1000);
          }

          showVolume();

        }
      setStartAnimation(true);
    }


  };


  var handleSVGAnimation = (action) => {
    var group = document.getElementsByClassName('voice-animation')[0];

    if(group !== null && group !== undefined && group.classList !== undefined){

      if(action === "start" && !group.classList.contains("active")){
        group.classList.add("active");
      } else {
        group.classList.remove("active");
      }
    }

  };


  const renderContent = (templateStatus) => {
    switch (templateStatus) {
      default:
      case "start-detection":
        return <>
          <div className='voice-template__intro voice-template__intro--beetles'>
            <div className='type-h1'>You're in! But are you a winner?</div>
              
              <p>To find out if you've won a prize worth screaming about, you'll need to say 'Beetlejuice' three times into your microphone… IF you're brave enough.</p>
              

              {/*<div className='voice-animation__elipse active'>
                <VoiceElipse />
              </div>*/}
              
              <div className='voice-template__cta-group'>
                <Button
                  wide
                  type="button"
                  title="Use my microphone"
                  onClick={getVoicePermission}
                />
                <Button
                  wide
                  type="button"
                  className="button--secondary"
                  title="I'm scared, just tell me"
                  onClick={handleAccessibleRoute}
                  />
              </div>
          </div>
        </>
      case "voice-allowed":
        return <>
          <div className='voice-template__intro'>
            <div className='type-h1'>We triple-dare you to say it</div>
            <p>Say the name three times into your microphone to set the juice loose.</p>
          </div>


          <h2></h2>
          <h2></h2>


          <div className='voice-template__content'>
            <div className='voice-animation'>
              <picture>
                <source media="(min-width: 768px)" srcSet={voiceBanner2x +" 2x, "+voiceBanner+" 1x"} type="image/png" />
                <img src={voiceBannerMob} srcSet={voiceBannerMob2x +" 2x, "+voiceBannerMob+" 1x"} 
                alt='' className='voice-animation__base' />
              </picture>
              <div className='voice-animation__text'>
                <div className='voice-animation__text__level voice-animation__text__level--l1'>Beetlejuice</div>
                <div className='voice-animation__text__level voice-animation__text__level--l2'>Beetlejuice</div>
                <div className='voice-animation__text__level voice-animation__text__level--l3'>Beetlejuice</div>
              </div>

              <div className='voice-animation__elipse'>
                <VoiceElipse />
              </div>

            </div>

            <Button
              type="button"
              className="button--text-link "
              title="If you're too scared or your mic is R.I.P., click here"
              onClick={handleAccessibleRoute}
              />
          </div>
                
          
        </>
    }
  }

  /* RENDER */
  return <>
    
    <Helmet>
      <meta charSet="utf-8" />
      <title>We dare you! | Maynards Bassetts - Beetlejuice </title>
    </Helmet>
    <div className="layout">
      
      <section className="layout-general layout-wrapper layout-wrapper--lines layout-wrapper--lines-column">
        <div className="layout-container layout-container--center voice-template">
          
          {renderContent(templateStatus)}
            
         
        </div>

        <Promo
        nobugs/>
        
      </section>
    </div>
  </>;
}