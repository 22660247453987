import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import VoiceRecognition from './VoiceRecognition';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import useSettingsContext from 'contexts/useSettingsContext';

export default () => {
  const settings = useSettingsContext();
  const competitionEntryState = useCompetitionEntryContext();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";

  let { entryPublicId } = useParams();

 /* if (competitionIsOpen && (entryPublicId) ) {
    return <VoiceRecognition entryPublicId={entryPublicId} />
  } else {
    return <Redirect to='/' />
  }*/
    return <VoiceRecognition entryPublicId={"123"} />
}

