import api from './api';

function addNpsScore(competitionEntryPublicId, score) {
    return api
        .post('customer-survey', {
            competitionEntryPublicId,
            score
        });
}

export default {
    addNpsScore
};