import React from 'react';
import './Validation.scss';

export default (props) => {

  if (!props.errors && !props.defaultMessage) {
    return null;
  }

  let errorPropertiesToHandle = ['globalerrors'];
  let errorMessages = [];
  let unhandledMessages = [];

  if (props.handlePropertyErrors) {
    props.handlePropertyErrors.forEach(value => {
      errorPropertiesToHandle.push(value.toLowerCase());
    });
  }
  
  if (props.errors) {
    var allErrors = props.errors.getAllErrors();

    allErrors.forEach(error => {
      let propLower = error.property.toLowerCase();
      if (errorPropertiesToHandle.indexOf(propLower) > -1) {
        errorMessages = errorMessages.concat(error.messages);
      } else {
        unhandledMessages  = errorMessages.concat(error.messages);
      }
    });
  }

  if (!errorMessages.length && unhandledMessages.length && props.defaultMessage) {
    errorMessages.push(props.defaultMessage);
  }

  if (!errorMessages.length) {
    return null;
  }

  if (errorMessages > 1) {
    return (
      <ul className="validation-summary">
        {errorMessages.map((error) => <li>{error}</li>)}
      </ul>
    );
  }

  return (
    <p className="validation-summary">{errorMessages[0]}</p>
  );
}