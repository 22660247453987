import React from 'react';
import { Helmet } from "react-helmet";
import { useRef } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useHistory } from 'react-router-dom';
import './Policies.scss';

import Promo from 'components/Promotion/Promo'

export default (props) => {
  const history = useHistory();
  const appendix = useRef(null);
  const clause6 = useRef(null);
  const clause13 = useRef(null);

  useEffectOnce(() => {
    if (history.location.hash.indexOf("products-list") > -1) {
      const section = document.querySelector('#products-list');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("prize-breakdown") > -1) {
      const section = document.querySelector('#prize-breakdown');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-10") > -1) {
      const section = document.querySelector('#clause-10');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }

    if (history.location.hash.indexOf("clause-11") > -1) {
      const section = document.querySelector('#clause-11');
      if(section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'start' }); }
    }
  });

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Maynards Bassetts - Beetlejuice</title>
    </Helmet>
    <section className="layout-general layout-wrapper layout-wrapper--lines">
      <div className="layout-container layout-container--slim layout-container--center layout-container--bottom-space">
        <div className='policies'>
          <h1 className="type-h1">T&C<span>s</span></h1>

          <div className='policies__content'>
            <p className='policies__disclaimer'>Please note, due to unforeseen circumstances, it is not possible to enter the Tesco Exclusive prize draw. The Tesco Exclusive prize will instead be allocated to the “Other retailers” prize pool.</p>
            <p className='policies__disclaimer'>For reasons beyond our control, Superdrug gift cards are no longer available as prizes. These will be replaced by Boots gift cards of equal value.</p>
            <br/>
            <ol>
              <li>
                <p>This Promotion is open to residents of the United Kingdom including the Channel Islands and the Isle of Man aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion.</p>
              </li>

              <li>
                <p>Purchase is necessary. <strong>Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their Prize.</strong> Internet access and a valid email address are required.</p>
              </li>

              <li>
                <p>Please note that while we want to encourage you to participate and enjoy the benefits of the Promotion we believe in responsible consumption of our products. DO NOT purchase more product than you can reasonably use or consume just to participate in this Promotion.</p>
              </li>

              <li>
                <p><strong>Promotion Period:</strong> Enter between 00:01 BST on the 1 August 2024 and 23:59 GMT on the 30 November 2024 inclusive.</p>
              </li>

              <li>
                <p><strong>To Enter:</strong></p>
                <ol type='a'>
                  <li>
                    <p><strong>Prize Draws:</strong> Purchase a pack of either Limited Edition Maynards Bassets Liquorice Mix 130g or Limited Edition Maynards Bassets Spooky Gums 130g (each a "Promotional Pack"), scan the QR code or visit <a href="http://www.maynardsbassetts.co.uk" target="_blank" rel="noopener noreferrer">www.maynardsbassetts.co.uk</a> and complete the online form providing your details (full name, email address and home address), the retailer the Promotional Pack was purchased from, the barcode and the batch code to be entered into the Other retailers Prize Draw and, for Sainsbury's customers, into the Sainsbury's exclusive Prize Draw.</p>
                  </li>
                  <li>
                    <p><strong>Winning Moments:</strong> All valid entries will also be submitted into a Winning Moments promotion set out at clause 6 and 7(a) below.</p>
                  </li>       
                </ol>
              </li>

              <li>
                <p>Maximum of 1 entry is permitted per person per day into the Winning Moments during the Promotion Period, with a maximum of 20 entries throughout the entire Promotion Period. Maximum of 2 entries are permitted per person per applicable Prize Draw. Upon entering the Prize Draws for Sainsbury's entrants will automatically be entered into the Other Retailer's Prize Draw which will count as the maximum 2 entries per day. One itemised receipt per entry. Receipt must state the time and date of purchase, which must pre-date the entry date and time, but be within the Promotion Period. No person may win more than 1 x Winning Moments Prize and 1 x Prize Draw Prize.</p>
              </li>

              <li>
                <p><strong>Winner Selection:</strong></p>
                <ol type="a">
                  <li><p><strong>Winning Moments:</strong> During the Promotion Period, 1,010 "winning moments" (days, hours, minutes, seconds) have been randomly selected by an independent verification service. The first entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the Prize from that winning moment will be rolled over, creating an additional winning moment.</p></li>
                  <li><p><strong>Prize Draws:</strong> 1 provisional winner from the Sainsbury’s Prize Pool and 2 provisional winners from the Other retailers Prize Pool, (all “provisional winners”) will be randomly selected from all valid entries for each Retailer during the Promotion Period. For your total confidence, the Prize Draws will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Promotion Period.</p></li>
                </ol>
              </li>

              <li>
                <p><strong>Prizes:</strong></p>
                <ol type="a">
                  <li><p><strong>Winning Moments:</strong> During the Promotion Period, there are a total of 1,010 Winning Moments Prizes to be won. Each Winning Moments winner will win 1 x £25 Gift Card for either Boots ('Spooky Beauty') or John Lewis ('Thrilling Homeware'), subject to the winner's choice.</p></li>
                  <li><p><strong>Prize Draws:</strong></p>
                    <ol type="i">
                        <li><p><strong>Sainsbury's Exclusive Prize:</strong> There will be 1 Sainsbury's Exclusive Prize winner. The Sainsbury's Exclusive Prize winner will win 1 x trip to York for the winner and a guest, including a two-night hotel stay with breakfast (minimum 3*), a choice between an Evening Ghost Bus Tour or Evening Ghost Walk, and £400 travel allowance and spending money.</p></li>
                        <li><p><strong>Other Retailers Prize:</strong> There will be 2 Other Retailers Prize winners. Each winner will win one of the following: 
                        <ol type="1">
                          <br></br>
                          <li>
                          <strong>London:</strong> One winner will win 1 x trip to London including a two-night spooky hotel stay in London (minimum 3*), one dinner at a spooky restaurant for the winner and 3 guests, return transfers from the winner’s home address to the London hotel and £200 spending money.
                          </li>
                          <li>
                          <strong>Edinburgh:</strong> One winner will win 1 x trip to Edinburgh for the winner and a guest, including a two-night hotel stay with breakfast (minimum 3*), an Evening Ghost Bus Tour and £400 travel allowance and spending money.
                          </li>
                        </ol>                    
                        </p></li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>
                <p><strong>Further Prize Details and Conditions:</strong></p>
                <ol type="a">
                    <li>
                      <p><strong>Winning Moments Prizes:</strong></p>
                      <ol type="i">
                            <li>
                              <p>John Lewis Gift Cards:</p>
                              <ol type="1">
                                <li><p>Winners will be provided an e-Gift Card via email.</p></li>
                                <li><p>Gift Cards will expire 24 months from purchase or last use. Buying a product or making a balance enquiry refreshes the 24-month expiry period.</p></li>
                                <li><p>See <a href="https://www.johnlewis.com/customer-services/shopping-with-us/terms-and-conditions" target="_blank" rel="noopener noreferrer">https://www.johnlewis.com/customer-services/shopping-with-us/terms-and-conditions</a> for John Lewis Gift Card Terms & Conditions.</p></li>
                              </ol>
                            </li>
                            <li>
                              <p>Boots eGift Cards:</p>
                              <ol type="1">
                                <li><p>Winners will be provided an e-Gift Card via email. </p></li>
                                <li><p>Gift Cards can only be used online at <a href="https://boots.com" target="_blank" rel="noopener noreferrer">www.boots.com</a>.</p></li>
                                <li><p>Gift Cards will expire 24 months from the date of purchase or the last transaction, whichever is longer.</p></li>
                                <li><p>See <a href="https:boots.cashstar.com/self_service/v2/about/terms_and_conditions" target="_blank" rel="noopener noreferrer">https://boots.cashstar.com/self_service/v2/about/terms_and_conditions</a> for Boots Gift Card Terms & Conditions.</p></li>
                              </ol>
                            </li>
                      </ol>
                    </li>
                    <li><p><strong>General Conditions for Sainsbury's, and Other Retailers Prizes:</strong></p>
                      <ol type="i">
                          <li><p>The trip can be taken <strong>from</strong> the 10th January 2025 onwards. The trip must be booked and taken <strong>by</strong> the 30th November 2025 (subject to availability).  At least 60 days' advance notice of preferred date of stay must be given. The Prize cannot be taken during the excluded dates listed at clause 9(b)(ii) below.</p></li>
                          <li><p>Stays may not be booked for the following dates: Valentine's Day, Christmas Day, New Year's Eve, Easter, school holidays and Bank Holiday periods in the UK.  The winner will forfeit the Prize if they fail to take the trip before 30th November 2025.</p></li>
                          <li><p>The trip dates are subject to availability and the Promoter will have the final discretion on the dates. </p></li>
                          <li><p>If a guest is a minor, then they should be accompanied by a responsible adult and with their parent's or guardian's written consent. The guest must be of a minimum age of five years old. </p></li>
                          <li><p>The accommodation is based on two people sharing one double or twin room, subject to availability. </p></li>
                          <li><p>The Promoter will pay the cost of the standard room plus related room taxes.</p></li>
                          <li><p>A valid credit or debit card will be required to check in to your room and will be used to guarantee any incidentals such as in-room calls or services.</p></li>
                          <li><p>The winner is solely responsible for any additional charges incurred at the hotel during their stay, including, without limitation, costs of meals, drinks, additional nights and services together with related taxes.</p></li>
                          <li><p>The winner and their guest must travel together in both directions. </p></li>
                          <li><p>All elements of the Prize package must be taken as part of the same trip.</p></li>
                          <li><p>It will be the winner's and their guest's responsibility to take out at their own cost all relevant insurance (including but not limited to health and travel insurance, insurance for theft, loss and damage to property) which may be required or prudent to be taken. </p></li>
                          <li><p>The winner must inform the Promoter of any wheelchair or any similar access needs.</p></li>
                          <li><p>For the avoidance of doubt, the Prize does not include transportation, food, beverages, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money) that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the Prize will be responsible for any such costs.</p></li>
                          <li><p>The winner is responsible for the behaviour of themselves and their guest(s) whilst taking the Prize. The Promoter reserves the right in its absolute discretion to exclude the winner and/or their guest(s) from participation in any aspect of the Prize if any party fails to comply with the directions of the Promoter or any companies associated with the Prize or if the winner and/or their guest(s) acts in a manner that is dangerous to themselves or to the public or is, in the opinion of the Promoter, anti-social in any manner or which causes a disturbance or nuisance to others. Failure to comply with these Terms and Conditions may result in refused entry, or the ticket holders being required to leave the event/venue.</p></li>
                          <li><p>The spending money and travel allowance (if applicable) will be provided to the winner via a Visa or Mastercard Gift Card, subject to the Promoter's discretion. </p></li>
                          <li><p>Winners are responsible for providing correct bank account details and the Promoter will not be liable for any Prize payments made into the wrong bank account.</p></li>
                          <li><p>Once bookings have been confirmed, no changes are permitted, and no alternatives are available. </p></li>
                          <li><p>Winners and guests travel at their own risk and, if for any reason any aspect of this promotion or Prize is not capable of running as planned, including war, terrorism, state of emergency, pandemic, or any other kind of disaster, tampering or computer virus, the Promoter reserves the right to cancel, terminate, modify or suspend the promotion or suspend, substitute or modify a Prize, subject to any written directions from a relevant regulatory authority.</p></li>
                          <li>
                            <p><strong>Sainsbury's and Other retailers Edinburgh Prizes:</strong></p>
                            <ol type="1">
                                <li><p>Except for the one guest permitted to you as part of your Prize, you are not allowed to bring any additional family members or guests on the trip. Your guest may not be selected through any further contest, promotion or commercial event. Once selected, your guest may not be changed without the express consent of Performance Entertainment (PE), which PE may grant or withhold in its sole discretion.</p></li>
                                <li><p><strong>Any potential winner and their proposed guest will be required to sign a Prize acceptance and release form in order to be eligible to win and/or participate in the Prize. If the proposed guest is under 18, the parent/legal guardian of the guest must sign the form on their behalf. </strong></p></li>
                                <li><p>Transfers are not included.</p></li>
                              </ol>
                          </li>
                          <li>
                            <p><strong>Sainsbury's Exclusive Prize only:</strong></p>
                            <ol type="1">
                                <li><p>The excursion selected from the Evening Ghost Bus Tour or Evening Ghost Walk will be subject to availability and at the final discretion of the Promoter.</p></li>
                            </ol>
                          </li>
                          <li>
                            <p><strong>Other Retailers Prize only:</strong></p>
                            <ol type="1">
                                <li><p>Return transfers from the winner's home address to the London hotel will be included for the winner and their guests. </p></li>
                                <li><p>The winner must inform the Promoter of any dietary requirements or allergens at least 2 weeks prior to attending the restaurant.</p></li>
                                <li>
                                  <p><strong>If a winner is from Northern Ireland, the Channel Islands or the Isle of Man for the Other retailers Prize only, the following conditions will apply: </strong></p>
                                  <br></br>
                                  <ol type="a">
                                    <li><p>Return transfers from the winner's home to their closest airport will be included for the winner and their guest/s. The Promoter will endeavour to provide flights from the winner's closest airport to a London airport, however the final airports selected will be subject to availability and the Promoter's discretion.</p></li>
                                    <li><p>Return transfers will be included from the London airport to the London hotel.</p></li>
                                    <li><p>Once tickets have been issued, they are only valid for flights, dates and times shown. No alternatives are available. Name changes to the flight once confirmed are not permitted.</p></li>
                                    <li><p>Airline seats are subject to availability.</p></li>
                                    <li><p>Check-in luggage may not be included in your flight ticket. The winner and their guests may take extra luggage at their own cost.</p></li>
                                    <li><p>The winner and their guests must abide by and are subject to the airline's published Conditions of Carriage.</p></li>
                                    <li><p>Lounge passes and upgrades are not permitted. Flights cannot be used in conjunction with the Promoter's/any loyalty programme, any other discount, discount coupon(s) or voucher(s), promotion(s) or special offer.</p></li>
                                    <li>
                                      <p>The Prize will be forfeited if:</p>
                                      <ol type="i">
                                        <li>the winner has not obtained all of the proper travel documents by the date specified by the Promoter;</li>
                                        <li>the winner has any immigration complications which prevent them from entering, leaving or travelling within the UK; or</li>
                                        <li>the winner has any medical, legal or any other reason that would prevent you from accepting and participating in the Prize.</li>
                                      </ol>
                                    </li>
                                    <li><p>The winner and their guests will require a passport valid for at least 6 months on date of travel; travel insurance is the responsibility of the winner and their guest. In the event the winner or their guest are unable to travel due to visa or other personal circumstances, no alternative tickets will be issued, and no compensation or other prize will be provided.</p></li>
                                  </ol>
                                </li>
                              </ol>
                          </li>
                      </ol>
                    </li>
                  </ol>              
              </li>

              <li>
                <p><strong>Winner Notification:</strong></p>
                <ol type="a">
                  <li>
                    <p><strong>Winning Moments:</strong> The provisional winning entrants will be instantly notified on screen alongside being sent a confirmation email within 3 working days, and will be asked to follow the instructions to claim their Prize. If a provisional winner does not follow the instructions on screen within 14 days of initial contact, the Promoter reserves the right to disqualify that provisional winner and return the Prize to the Promotion Winning Moments Prize pool which may be won by another entrant during the Promotion Period.
                    <br></br>Any claims received that don't meet these requirements or are received more than 14 days after the close of the Promotion Period, will be void and invalid and we reserve the right to return the Prize to the Promotion Winning Moments Prize pool which may be won by someone else during the Promotion Period.</p>
                  </li>
                  <li>
                    <p><strong>Prize Draws:</strong> Provisional winners will be contacted via the email address provided on entry within 5 working days of Winner Selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the provisional winners. Once eligibility has been confirmed, the provisional winners will be confirmed as a winner. If a provisional winner does not respond to the initial contact within 14 days, we reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p>
                  </li>

                </ol>
                <p>Winners can get into the spooky spirit by optionally saying "Beetlejuice" 3 times out loud whilst claiming their Prize.</p>
              </li>

              <li>
                <p><strong>Prize Acceptance:</strong> Once eligibility of a Prize is confirmed and the Prize is accepted, notification to arrange fulfilment of the Prizes will take place within 28 days of acceptance of the Prizes. In the unlikely event that a winner has not received communication to arrange their Prize the Winner must inform the Promoter by emailing <a href="mailto:maynardsbassetts@promowinners.com" target="_target" rel="noopener noreferrer">maynardsbassetts@promowinners.com</a> for the Other retailers Prize or Winning Moments Prizes or by emailing <a href="mailto:info@performance-entertainment.com" target="_target" rel="noopener noreferrer">info@performance-entertainment.com</a> for the Sainsbury's Exclusive Prize. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.</p>    
              </li>

              <li>
                <p>Provided no objection is received from the winners, a winners list containing the surname and county of residence of the Prize Draw Prize winners will be made available by the Promoter 4 weeks after the close of the Promotion Period, for a period of 8 weeks. It will be available by sending a request via email to <a href="mailto:maynardsbassetts@promowinners.com" target="_target" rel="noopener noreferrer">maynardsbassetts@promowinners.com</a>. Winners can object to their details being published during the Winner Notification process. Without prejudice, the Promoter will provide winner information to the Advertising Standards Authority when requested by them.</p>    
              </li>

              <li>
                <p>The Promoter may request that the winners participate in publicity arising from the Promotion. Participation is at the winner's discretion and is not a condition of Prize Acceptance.</p>    
              </li>

              <li>
                <p>The Promoter seeks to run a fair and secure promotion and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entry/ies (and any of your associated aliases) will be disqualified. Any Prize awarded may be void and recoverable, and the Promoter reserves the right to bar you from entering future promotions for a period of at least six months. For this reason, the Promoter reserves the right at any point to:</p>    
                <ol type="a">
                  <li>Verify the eligibility of entrants and/or provisional winners through multiple means by requesting such information it consider reasonably necessary for this purpose. Entry or a Prize may be withheld until verification is completed.</li>
                  <li>Disqualify entries that are not made directly by the individual entering the Promotion.</li>
                  <li>Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable or Mailinator.</li>
                  <li>Disqualify bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means.</li>
                  <li>Disqualify entries beyond the maximum allowed, or those using techniques such as 'script', 'brute force', masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means.</li>
                  <li>Disqualify entrants who tamper with the entry process.</li>
                  <li>Disqualify entries which, in some other way, do not meet the requirements of these Terms & Conditions.</li>
                  <li>Cancel, modify or suspend the Promotion should it not be capable of running as planned for reasons including but not limited to tampering, unauthorised intervention, fraud, dishonesty, technical failures, or any other causes beyond the control of the Promoter which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Promotion.</li>
                </ol>
              </li>

              <li>
                <p>The Prizes are not transferable or exchangeable and cannot be redeemed for monetary value or for any other form of compensation. If for any reason a Prize and/or any element of a Prize is not available, the Promoter reserves the right, at its sole discretion to substitute that Prize or element of the Prize for another of equal or greater value.</p>
              </li>

              <li>
                <p>
                No responsibility can be taken for entries which are lost, delayed, corrupted, damaged, misdirected or incomplete or which cannot be delivered for any technical, delivery or other reason. Proof of sending will not be accepted as proof of receipt. The Promoter does not guarantee continuous or secure access to <a href="https://www.maynardsbassetts.co.uk" target="_blank" rel="noopener noreferrer">www.maynardsbassetts.co.uk</a>.
                </p>
              </li>

              <li>
                <p>Warner Bros. Entertainment UK Limited. and its parent, affiliates and subsidiaries are not responsible for the promotion, administration or execution of this promotion.</p>
              </li>

              <li>
                <p>The Promoter and its associated agencies and companies are not liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person's negligence) in connection with this Promotion (JS14374) or accepting or using the/a Prize(s), except for any liability which cannot be excluded by law. Nothing will exclude the Promotor's liability for death or personal injury as a result of its negligence.</p>
              </li>

              <li>
                <p>The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances.</p>
              </li>

              <li>
                <p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters' Data Privacy Notice which can be found at <a href="https://eu.mondelezinternational.com/privacy-notice" target="_blank" rel="noopener noreferrer">https://eu.mondelezinternational.com/privacy-notice</a>. The Promoter may disclose entrants' personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.</p>
              </li>

              <li>
                <p>If any part/s of these Terms & Conditions are legally ruled to be invalid, illegal or unenforceable, the remainder will not be affected and will continue in full force and effect.</p>
              </li>

              <li>
                <p>By entering, you will be deemed to have agreed to be bound by these Terms & Conditions.</p>
              </li>

              <li>
                <p>If there is a discrepancy between these Terms & Conditions and those in any promotional material, these Terms & Conditions will prevail.</p>
              </li>

              <li>
                <p>These Terms & Conditions are governed by the laws of England and Wales and their interpretation and application will be subject to the non-exclusive jurisdiction of the courts of England and Wales. Residents of Scotland and Northern Ireland may bring proceedings in their own jurisdiction.</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <Promo nobugs/>
  </>;
}