import axiosFactory from 'axios';
import ValidationException from 'validators/ValidationException';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import errorMessages from 'resources/errorMessages';

const API_PATH = '/api/';
const XSRF_VERBS = ['post', 'put', 'patch', 'delete'];
const DEV_URL = 'http://localhost:7018';

const axios = createAxiosInstance();

function mapResponseData(response) {
  if (!response || !response.data) {
      return null;
  }
  return response.data.data;
}

function handleApiError(error) {
  let response = error.response;  

  if (response && response.status === 400 && response.data && response.data.errors) {
    // validation error
    let errors = new ValidationErrorCollection();

    if (!Array.isArray(response.data.errors)) {
      for (const property in response.data.errors) {        
        if(!property.length || !response.data.errors[property] || !property) {          
          errors.addGlobalError(response.data.errors[""][0]);
        } else {
          errors.addError(property.charAt(0).toLowerCase() + property.slice(1), response.data.errors[property]);
        }
      }
    } else {
      response.data.errors.forEach(responseError => {       
  
        let replacementMessage = errorMessages[responseError.errorCode];
        //let replacementMessage = null;
        
        if (replacementMessage) {
          responseError.message = replacementMessage;
        }
        
        if (!responseError.properties || !responseError.properties.length || !responseError.properties[0]) {
          errors.addGlobalError(responseError.message);
        } else {
          responseError.properties.forEach(propertyName => {
            let property = propertyName.charAt(0).toLowerCase() + propertyName.slice(1)
            errors.addError(property, responseError.message);
          });
        }
      });
    }    

    throw new ValidationException('The API returned with validation errors', errors);
  } else {
    // e.g. 500, 404 etc
    throw error;
  }
}

function createAxiosInstance() {
  let isDevEnvironment = process.env.NODE_ENV === 'development';
  let baseUri = (isDevEnvironment ? DEV_URL : '') + API_PATH;

  return axiosFactory.create({
      withCredentials: isDevEnvironment,
      baseURL: baseUri
  });
}

function updateXSRFToken(token) {
  for (const verb of XSRF_VERBS) {
    axios.defaults.headers[verb]['X-XSRF-Token'] = token;
  }
}

async function get (url) {
  let result = await axios
    .get(url)
    .catch(handleApiError);

  return mapResponseData(result);
}

async function post (url, data) {
  let result = await axios
    .post(url, data)
    .catch(handleApiError);

  return mapResponseData(result);
}

export default {
  get,
  post,
  updateXSRFToken
};
