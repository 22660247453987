import React from 'react';
import classList from 'react-classlist-helper';
import Link from 'components/Link/Link';
import { ReactComponent as FbSvg } from 'assets/images/facebook.svg';
import { ReactComponent as Instavg } from 'assets/images/instagram.svg';

import './SocialButtons.scss';

export default (props) => {
  const classMap = {
    "social-buttons": true,
    "social-buttons--images": !!props.hasImages,
  };
  return (
    <ul className={classList(classMap)} >
     <li className="social-buttons__item">

     <Link 
     className="social-buttons__btn"
                  analytics={{evnt: "socialLinks", category: "FacebookLink", action: "OpenFacebookLink"}}
                  href="https://www.facebook.com/MaynardsBassetts/"
                  target="_blank"
                  title="Maynards Bassetts on Facebook" >
                    <FbSvg />
              </Link>
              
     </li>
     <li className="social-buttons__item">
        <Link 
          className="social-buttons__btn"
          analytics={{evnt: "socialLinks", category: "InstagramLink", action: "OpenInstagramLink"}}
          href="https://www.instagram.com/maynardsbassetts/"
          target="_blank"
          title="Maynards Bassetts on Instagram" >
            <Instavg />
        </Link>
     </li>
    </ul>
  )
}