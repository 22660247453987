import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Button from 'components/Button/Button';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';
import ThankYouHero from './ThankYouHero';
import PrizeBlock from 'components/Prize/PrizeBlock';
import Video from 'components/Video/Video';
import Link from 'components/Link/Link';

import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';

import PrizeTierOne from 'assets/images/campaign/prizes/prize_top_mob.png';
import PrizeTierOne2x from 'assets/images/campaign/prizes/prize_top_mob@2x.png';
import PrizeTierOneDesk from 'assets/images/campaign/prizes/prize_top_desk.png';
import PrizeTierOneDesk2x from 'assets/images/campaign/prizes/prize_top_desk@2x.png';

import VideoPoster from "assets/images/campaign/video-poster-desk.png"
import './Thankyou.scss';


export default () => {
  let { entryPublicId } = useParams();  

  let [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsMopup = compPhaseId === "mop-up";

  let competitionEntryContext = useCompetitionEntryContext();
  //let [entry, setEntry] = useState(competitionEntryContext.result);
  let [entry, setEntry] = useState("");

  let [showNps, setShowNps] = useState(null);



  useEffect(() => {
    async function fetchData() {
      await competitionEntryService.getCompetitionEntry(entryPublicId)
      .then((result) => {            
          if(result) {
            setEntry(result); 
            
            if(result.nps !== null) {              
              setShowNps(false);
            } else {              
              timer.delay(2500).then(() => setShowNps(true));
            }


            var email = competitionEntryContext.email;
            //Had to go here, as not triggered on last page
            if (email != null) {
              // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)

              analyticsService.logEvent("forms", "Forms", {
                action: "EmailCapture",
                email
              });

              analyticsService.logEvent("forms", "Forms", {
                action: "RegistrationSuccess"
              });

              if (result.hasWon) {
                analyticsService.logEvent('event', 'Prize', {
                  action: result.prizePublicId
                });
              }
            } 
          } 
      })
      .catch(err => console.log(err) );
    }

    if (entryPublicId && !entry) {
      fetchData();
    }

      
  }, [entry]);


  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }


  const renderWinner = () => {
    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Thank you | Maynards Bassetts - Beetlejuice</title>
      </Helmet>

      <section className="layout-wrapper layout-wrapper--lines thankyou__banner">
            <ThankYouHero 
            winner
            />
      </section>

      <section className="layout-wrapper thankyou bg-black">
      
        <div className="layout-container layout-container--center">
          
          <h1 className="type-h1 type-h1--white">You've won a spooky prize!</h1>
          <p className="thankyou__para">
          <strong>Now you can set the juice loose with a £25 homeware voucher to deck out your digs, or a £25 beauty voucher to treat yourself to some menacing makeup for a big night out at the cinema.</strong>         
           </p>       
          <p className="thankyou__para">
          Note: All wins have to be verified as valid entries, so please keep your receipt/id as you may need to provide proof.          
          </p>     
          <p className="thankyou__para">
          Winners will be notified via email within 3 working days, and have 14 days to claim their prize.          
          </p>      

        </div>

        {renderNpsBanner(entryPublicId)}
      </section>
      <section className="layout-wrapper layout-wrapper--goo-black bg-primary prize-block-section">
        <div className="layout-container layout-container--center layout-container--wide">
          <div className="thankyou__content">
            <h1 className="type-h1 type-h1--white">And don't forget…</h1>
            <p className="thankyou__para thankyou__para--wide">
              ...you're still in the draw for a chance to win a spooky trip to one of the UK's most haunted cities. Enjoy a two-night hotel stay including travel, breakfast, a spooky experience and £200 spending money.            </p>     
          </div>
          <div className="thankyou__prize">
            <PrizeBlock
            imageMob={PrizeTierOne}
            imageMobLarge={PrizeTierOne2x}
            image={PrizeTierOneDesk}
            imageLarge={PrizeTierOneDesk2x}
            alt="Spooky 2 night city break"
            />  
          </div>
        </div>
      </section>
      <section className="layout-wrapper layout-wrapper--goo-purple">
        <div className="layout-container layout-container--center layout-container--medium">
        <h1 className="type-h1">Take a peek at the ghost with the most</h1>
        <Video 
          src="fFalF88454I"
          poster={VideoPoster}
        />
        <Link className="thankyou__link" title="Get Tickets Today" lozenge href="https://www.warnerbros.co.uk/movies/beetlejuice-beetlejuice" target="_blank">Get Tickets Today</Link>

        </div>
      </section>
      
    </>
    );

  }

  const renderLoser = () => {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry | Maynards Bassetts - Beetlejuice</title>
        </Helmet>

        <section className="layout-wrapper layout-wrapper--lines thankyou__banner">
            <ThankYouHero 
            loser
            />
      </section>

        <section className="layout-wrapper thankyou thankyou--loser bg-black">
        
          <div className="layout-container layout-container--center">
            
            <h1 className="type-h1 type-h1--white">Sorry, it's not showtime this time</h1>
            <p className="thankyou__para">
              You haven't won a prize yet, but you're still in the draw for a chance to win a spooky trip to one of the UK's most haunted cities. Enjoy a two-night hotel stay including travel, breakfast, a spooky experience and £200 spending money.            
            </p>
            <p className="thankyou__para">
              <strong>Good luck!</strong>
            </p>
          </div>

          {renderNpsBanner(entryPublicId)}
        </section>
        <section className="layout-wrapper layout-wrapper--goo-black bg-primary prize-block-section">
          <div className="layout-container layout-container--wide layout-container--center ">
            <div className="thankyou__prize">
              <PrizeBlock
              imageMob={PrizeTierOne}
              imageMobLarge={PrizeTierOne2x}
              image={PrizeTierOneDesk}
              imageLarge={PrizeTierOneDesk2x}
              alt="Spooky 2 night city break"
              />  
            </div>
          </div>
        </section>

        <section className="layout-wrapper layout-wrapper--goo-purple">
          <div className="layout-container layout-container--center layout-container--medium">
            <h1 className="type-h1">Take a peek at the ghost with the most</h1>
            <Video 
              src="fFalF88454I"
              poster={VideoPoster}
            />
                    <Link className="thankyou__link" title="Get Tickets Today" lozenge href="https://www.warnerbros.co.uk/movies/beetlejuice-beetlejuice" target="_blank">Get Tickets Today</Link>
          </div>
        </section>


      </>
    );
  }

  return (
    <>
    {
      (entryPublicId && !entry) ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Thank You | Maynards Bassetts - Beetlejuice</title>
          </Helmet>
          <section className="layout layout-general layout-wrapper layout-wrapper--lines">
            <div className="layout-container layout-container--slim layout-container--center layout-container--bottom-space">
            
              <h1 className="type-h1">Loading...</h1>
            </div>
          </section>
        </>
      ) : (
        (entry.hasWon) ? (
          renderWinner()
        ) : (
          renderLoser()
        )
      )
    }

    </>

  );
}
