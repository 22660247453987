import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Home from './Home/Home';
import RegistrationRouteGuard from './Forms/RegistrationRouteGuard';
import ClaimRouteGuard from './Forms/ClaimRouteGuard';
import TermsAndConditionsRouteGuard from './Policies/TermsAndConditionsRouteGuard';
import CookiePolicy from './Policies/Cookies';
import Thankyou from './Thankyou/Thankyou';
import FourOhFour from './Errors/FourOhFour';
import FAQsRouteGuard from './FAQs/FAQsRouteGuard';
import ForgottenPasswordRouteGuard from './Forms/ForgottenPasswordRouteGuard';
import ForgottenPasswordConfirmRouteGuard from './Forms/ForgottenPasswordConfirmRouteGuard';
import useCompetitionEntryState from 'contexts/useCompetitionEntryContext';
import VoiceRecognitionRouteGuard from './VoiceRecognition/VoiceRecognitionRouteGuard';


export default (props) => {
  let competitionEntryState = useCompetitionEntryState();
  let location = useLocation();

  useEffect(() => {
    if (competitionEntryState.allowAudio && competitionEntryState.localStream !== null) {
      var localStream = competitionEntryState.localStream;
      /* //MB - comented for chrome bug
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream)=>{
        stream.getTracks().forEach((track) => {
          track.stop();
          track.enabled= false;
          stream.removeTrack(track);
        })
      });*/
      
      localStream.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
        localStream.removeTrack(track);
      });
      competitionEntryState.setAudioUse(false, null);
    }
  }, [location.pathname]);

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route 
        exact 
        key="home"
        path="/">
        <Home />
      </Route>
       <Route 
        exact 
        key="registration"
        path="/registration">
        <RegistrationRouteGuard />
      </Route>
      <Route 
        exact 
        key="voiceinteraction"
        path="/voice-interaction/:entryPublicId">
        <VoiceRecognitionRouteGuard />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou/:entryPublicId">
        <Thankyou />
      </Route>
      <Route 
        exact 
        key="faqs"
        path="/faqs">
      <FAQsRouteGuard />
      </Route>
      <Route 
        exact 
        key="terms-and-conditions"
        path="/terms-and-conditions">
        <TermsAndConditionsRouteGuard />
      </Route>
      <Redirect exact from="/cookies" to="/cookie-policy" />
      <Route 
        exact 
        key="cookie-policy"
        path="/cookie-policy">
        <CookiePolicy />
      </Route>
     <Route 
        exact 
        key="claim"
        path="/claim/:token">
        <ClaimRouteGuard />
      </Route>     
      <Route>
        <FourOhFour />
      </Route>
    </AnimatedSwitch>
  );
}
