import api from './api';

function getSettings() {
    return api
        .get('settings')
        .then(settings => {
            
            api.updateXSRFToken(settings.csrfToken);

            return settings;
        });
}

export default {
    getSettings
};