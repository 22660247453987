import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';
import analyticsService from 'services/analyticsService';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';

import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import DateInput from 'components/Forms/DateInput';
import CustomDropdownField from 'components/Forms/CustomDropdownField';
import Promo from 'components/Promotion/Promo';


import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';
import Form, { createOnChangeHandler } from './Form';


import './Form.scss';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);

  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
 
  let [retailer, setRetailer] = useState("");
  let [warnersOptIn, setWarnersOptIn] = useState(false);
  let [terms, setTerms] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [thirdPartyOptIn, setThirdPartyOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  const [shuffledRetailerList, setShuffledRetailerList] = useState([]);


  let [showValidationModal, setShowValidationModal] = useState(false);



  useEffect(()=>{
    
    if(validation.errors.hasErrors && !validation.acknowledged){
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }
    
  }, [validation])

  
  useEffect(()=>{
    setDoB(null);
  }, [optIn])
  
  const createCommand = () => {
    let model = {
      firstName,
      lastName,
      email,
      emailConfirm,
      country: "GB", //settings.country,
      dob,
      retailerId: retailer,
      terms,
      optIn,
      thirdPartyOptIn,
      warnersOptIn,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      recaptcha2,
      phaseId: compPhaseId
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  const retailersList = [
    { "value": "Sainsburys", "label": "Sainsburys"},
    { "value": "Tesco", "label": "Tesco"},
    { "value": "Spar", "label": "Spar"},
    { "value": "Budgens", "label": "Budgens"},
    { "value": "Londis", "label": "Londis"},
    { "value": "WHSmith", "label": "WHSmith"},
    { "value": "Waitrose", "label": "Waitrose"},
    { "value": "Nisa", "label": "Nisa"},
    { "value": "Costcutter", "label": "Costcutter"},
    { "value": "Independent Retailer", "label": "Independent Retailer"},
  ];


  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }
  

  useEffectOnce(() => {
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);
    let shuffledList = shuffle(retailersList);
    shuffledList.push({ "value": "Other", "label": "Other"});

    setShuffledRetailerList(shuffledList);

    window.setTimeout(function(){
      try 
      {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {
          
            window.grecaptcha.ready(() => {
                window.grecaptcha.render($grecaptchav2, {
              'sitekey' : '6Lc4HfcpAAAAALC00kYgDX7qNYTaP31z9KL20aZT',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
                });
          });
        }
      }catch{}
  }, 1000);
    
  });

  const onFormSuccess = (result) => {

    analyticsService.logEvent("forms", "Forms", {
      action: "RegistrationSuccess"
    });
  
    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);   

    let urlData =  `/${result.entryPublicId}`;
    history.push(`/voice-interaction${urlData}`);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown"); 
  }


  /* RENDER */
  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Maynards Bassetts - Beetlejuice</title>
    </Helmet>
    <section className="layout layout-general layout-general--full layout-wrapper layout-wrapper--lines">
      <div className="layout layout-general  layout-general--mt0  layout-wrapper layout-wrapper--sweets">

      <div className="layout-container layout-container--slim layout-container--center">
        
        <div className="form__intro">
          <h1 className="type-h1">Your Details</h1>
          <p >
          We just need some additional information from you to check that you’re eligible to enter, and so we can contact you if you’re one of the lucky winners! If you are, then make sure you have your receipt so you’re able to claim your prize.          </p>
          
          <p>
            Fields marked with * are mandatory.
          </p>
        </div>
        <Form 
          id="registration"
          submitTitle="Enter"
          onSuccess={onFormSuccess}
          formClass="form form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            { evnt: "form", category: "RegistrationForm", action: "EmailCapture", label: email} ,
            (dob && dob.isValid) &&
            { evnt: "form", category: "RegistrationForm", action: "DateOfBirth", label: dob.date}
          ]}
        >
          
          { validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary 
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              
              label="* First Name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              maxCharLenght={60}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="* Last Name"
              id="lastName"
              onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
              value={lastName}
              hasErrors={!!validation.errors.lastName}
              maxCharLenght={60}
            />
            <ValidationMessage errors={validation.errors.lastName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted 
              label="* Email Address"
              id="email"
              inputMode="email"
              type="text"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              maxCharLenght={150}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField 
              label="* Confirm Email Address"
              id="emailConfirm"
              autoComplete="new-password"
              inputMode="email"
              type="text"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset"> 
            <CustomDropdownField
              label="* Retailer product was bought from"
              id="retailerId"
              value={retailer}
              onChange={createOnChangeHandler(validation, 'retailerId', setRetailer)}
              hasErrors={!!validation.errors.retailerId}
              selectAnalytics={{evnt: "form", category: "RegistrationForm", action: "SelectRetailer"}}
              options={shuffledRetailerList}
            />
            <ValidationMessage errors={validation.errors.retailerId} />
          </fieldset>
          
      
          <fieldset className="form__fieldset">
            <Checkbox 
              id="terms" 
              label="* Agree&nbsp;<a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>T&Cs</a> and confirm 18+."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckTandCs"}}
            />
            <ValidationMessage errors={validation.errors.terms} />    
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
              id="optIn" 
              label="If you’d like to receive future news, competitions and offers from Maynards Bassetts by email, please tick this box. If you no longer wish to receive emails, you may unsubscribe at any time."
              onChange={setOptIn} 
              value={optIn}
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckOptIn"}}
            />

            {
              optIn && 
              <>
              <br/>
                <div className='form__date-entry'>
                  <label
                    className="textfield__label textfield__label--dob"
                    htmlFor="">
                    If you'd like to receive future news, competitions and offers from Maynards Bassetts by email, please tick this box. If you no longer wish to receive emails, you may unsubscribe at any time.
                  </label>
                  <br/>
                  
                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
                </>
              }
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox 
              id="thirdPartyOptIn" 
              label="Tick this box to grant Maynards Bassetts permission to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant to you. Maynard Bassetts is an affiliate brand of Mondelez International."
              onChange={setThirdPartyOptIn} 
              value={thirdPartyOptIn}
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckThirdPartyOptIn"}}
            />

            <Checkbox 
              id="warnersOptIn" 
              label="I consent for my name and email address to be shared with Warner Bros. UK and I am happy to be added to their email list and sent their newsletter. Warner Bros. UK and its affiliates may use my email address to send updates/news, ads, and offers. I can opt out or learn more about my rights via the <a href='https://www.wbdprivacy.com/policycenter/b2c/' rel='noreferrer noopener' target='_blank'>Privacy Policy</a>."
              onChange={createOnChangeHandler(validation, 'warnersOptIn', setWarnersOptIn)} 
              checkboxAnalytics={{evnt: "form", category: "RegistrationForm", action: "CheckWarnersOptIn"}}
            />
          </fieldset>
          
          <fieldset className="form__fieldset form__fieldset--captcha">
            <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
            <div className="g-recaptcha-v2" data-sitekey="6Lc4HfcpAAAAALC00kYgDX7qNYTaP31z9KL20aZT"
            data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
            <ValidationMessage errors={validation.errors.recaptcha2} /> 
          </fieldset>

          { showValidationModal &&
            <Modal 
              panel 
              hideClose
              error
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Oops">
              <ValidationSummary 
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              { !validation.errors.globalErrors.length ? (
                  <Button title="Try again" onClick={onDismissModal} />
                ) : (
                  <>
                    <Button title="Ok, got it" href="/" />
                    <Link route title="See full terms and details" to="/terms-and-conditions" onClick={onDismissModal}>See full terms and details</Link>
                  </>
                )
              }
            </Modal>
          }
        </Form>
        
        <p className="form__policy">Please make sure you have read and understood the <a href="https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/" rel="noopener noreferrer" target="_blank">Maynards Bassetts’ Privacy Policy</a> and <a href='https://www.wbdprivacy.com/policycenter/b2c/' rel='noreferrer noopener' target='_blank'>Warner Bros. Privacy Policy.</a> and agree to the processing of your personal data.</p>
      </div>
      </div>
    </section>
    <Promo/>
  </>;
}