import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import prizeClaimService from 'services/prizeClaimService';
import prizeClaimValidator from 'validators/prizeClaimValidator';

import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import Button from 'components/Button/Button';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';
import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import Promo from 'components/Promotion/Promo';

import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.png';

import Form, { createOnChangeHandler } from './Form';
import analyticsService from 'services/analyticsService';
import CustomDropdownField from 'components/Forms/CustomDropdownField';

import './Claim.scss';
import './Form.scss';


export default (props) => {
  let settingsContext = useSettingsContext();
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let [settings, setSettings] = React.useContext(SettingsContext);
  let [token, setToken] = useState(props.token);
  let [otpAvilable, setOtpAvilable] = useState(props.otpAvilable);
  let [otp, setOtp] = useState(props.otpAvilable);
  let [claim, setClaim] = useState();  
  let [sent, setSent] = useState(false);
  let [voucher, setVoucher] = useState(false);
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [town, setTown] = useState(null);
  let [county, setCounty] = useState(null);
  let [country, setCountry] = useState(null);
  let [postcode, setPostcode] = useState(null); 
  let [phasePublicId, setPhasePublicId] = useState('');

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [prizePublicId, setPrizePublicId] = useState("");
  let [prizeCategory, setPrizeCategory] = useState('');


  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });


  const createCommand = () => {
    let command = {
      firstName,
      lastName,
      address1,
      address2,
      town,
      county,
      country,
      postcode : postcode ? postcode.toUpperCase() : postcode,
      token,
      voucherTypeId : voucher,
      recaptcha2,
      phaseId: phasePublicId,
      prizePublicId,
      otpAvilable: props.otpAvilable ? props.otpAvilable : false,
      otp: props.otp ? props.otp : "",
      flowId: props.flowId ? props.flowId : null,
      entryFlowToken: props.entryFlowToken ? props.entryFlowToken : null
    };

    return command;
    
  }

  var handleReCaptchaV2 = (val) => {
    if(val !== null && val !== ""){
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }

  const prizeList = [
    { "value": "voucher-house", "label": "Thrilling Homeware Voucher"},
    { "value": "voucher-makeup", "label": "Spooky Makeup voucher"},
  ];

  
  useEffect(() => {   
      if(claim !== undefined && claim.canClaim !== undefined && claim.canClaim === true)
      {
        window.setTimeout(function(){
          try 
          {
            let $grecaptchav2 = document.querySelectorAll('.g-recaptcha-v2')[0];
            if ($grecaptchav2) {
             
                window.grecaptcha.ready(() => {
                    window.grecaptcha.render($grecaptchav2, {
                  'sitekey' : '6Lc4HfcpAAAAALC00kYgDX7qNYTaP31z9KL20aZT',
                  'callback': handleReCaptchaV2,
                    'expired-callback': handleReCaptchaV2Expired
                    });
              });
            }
          }catch{}

        }, 1000);
      
      }
    
  }, [claim]);


  useEffect(() => {
    document.body.classList.remove("overflow-hidden-mediumdown");
    async function fetchData() {
      let result = await prizeClaimService.getPrizeClaim(token);
      if(result) {
        setClaim(result);
        
        if(result.prize) {
          setPrizeCategory(result.prize.category.title);
          setPrizePublicId(result.prize.publicId);          
        }
        setCountry(result.country);
        setPhasePublicId(result.phasePublicId);
      }
    }

    if (token && !claim) {
      fetchData();
    }
  }, [claim, token]);

  if (token && !claim) {
    return (
      <div>Loading...</div>
    )
  }



const onFormSuccess = () => {
  analyticsService.logEvent("form", "ClaimForm", {
    action: "SubmitClaimForm",
    label: prizeCategory
  });

  return setSent(true);
}

const onDismissModal = () => {

  setValidation(prevState => ({ ...prevState, acknowledged: true }));
  
  document.body.classList.remove("overflow-hidden-mediumdown");
}


  if (!claim.canClaim) {
    return (
      <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Sorry! | Maynards Bassetts - Beetlejuice</title>
      </Helmet>
        <section className="layout-general layout-general--full layout-wrapper layout-wrapper--lines">
          <div className="layout-container layout-container--center claim">
            <h1 className="type-h1">Sorry!</h1>
            <div className="form__intro">
              <p >{claim.error.message}</p>
            </div>  
          </div>
        </section>
      </>
    )
  }

  return (  
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Claim your prize | Maynards Bassetts - Beetlejuice</title>
    </Helmet>
    <section className="layout-general layout-general--full layout-wrapper layout-wrapper--lines">
      <div className="layout-container layout-container--center claim">
        {!sent ? 
          (
           
           <>
            <h1 className="type-h1">It's spine-chilling prize time!</h1>
            <div className="form__intro">
              <p>Fill in your details and choose your prize below. We'll be visiting your inbox soon.</p> 
              <p>Fields marked with * are mandatory</p>
            </div>
                
            
            <Form 
              id="claim"
              submitTitle={"Claim"}
              formClass="form form--slim"
              onSuccess={onFormSuccess}
              command={{
                model: createCommand,
                service: prizeClaimService.addPrizeClaim
              }}
              validation={{
                validator: prizeClaimValidator,
                onValidation: setValidation
              }}
             
            >
              
              { validation.errors.hasErrors && validation.acknowledged &&
                <ValidationSummary 
                  errors={validation.errors}
                  handlePropertyErrors={['RecaptchaToken']}
                />
              }

              <fieldset className="form__fieldset"> 
                <CustomDropdownField
                  label="* Choose your £25 voucher"
                  id="voucherTypeId"
                  value={voucher}
                  onChange={createOnChangeHandler(validation, 'voucherTypeId', setVoucher)}
                  hasErrors={!!validation.errors.voucherTypeId}
                  selectAnalytics={{evnt: "form", category: "ClaimForm", action: "SelectVoucher"}}
                  options={prizeList}
              />
              <ValidationMessage errors={validation.errors.voucherTypeId} />

            </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted
                  label="* First name"
                  id="firstName"
                  onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
                  value={firstName}
                  hasErrors={!!validation.errors.firstName}
                  maxCharLenght={60}
                />
                <ValidationMessage errors={validation.errors.firstName} />
              </fieldset>
              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* Last name"
                  id="lastName"
                  onChange={createOnChangeHandler(validation, 'lastName', setLastName)}
                  value={lastName}
                  hasErrors={!!validation.errors.lastName}
                  maxCharLenght={60}
                />
                <ValidationMessage errors={validation.errors.lastName} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* Address line 1"
                  id="address1"
                  onChange={createOnChangeHandler(validation, 'address1', setAddress1)}
                  value={address1}
                  hasErrors={!!validation.errors.address1}
                  maxCharLenght={255}
                />
                <ValidationMessage errors={validation.errors.address1} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="Address line 2 (optional)"
                  id="address2"
                  onChange={createOnChangeHandler(validation, 'address2', setAddress2)}
                  value={address2}
                  hasErrors={!!validation.errors.address2}
                  maxCharLenght={255}
                />
                <ValidationMessage errors={validation.errors.address2} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted
                  label="* Town/City"
                  id="town"
                  onChange={createOnChangeHandler(validation, 'town', setTown)}
                  value={town}
                  hasErrors={!!validation.errors.town}
                  maxCharLenght={35}
                />
                <ValidationMessage errors={validation.errors.town} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label="* County"
                  id="county"
                  onChange={createOnChangeHandler(validation, 'county', setCounty)}
                  value={county}
                  hasErrors={!!validation.errors.county}
                  maxCharLenght={50}
                />
                <ValidationMessage errors={validation.errors.county} />
              </fieldset>

              <fieldset className="form__fieldset">
                <TextFieldCharRestricted 
                  label={ country === "ROI" ? `* Eircode`:`* Postcode` }
                  id="postcode"
                  onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
                  value={postcode}
                  hasErrors={!!validation.errors.postcode}
                  maxCharLenght={8}
                />
                <ValidationMessage errors={validation.errors.postcode} />
              </fieldset>       


              <fieldset className="form__fieldset">
                  <ReCaptcha captchaSettings={settingsContext.captchaSettings} />    
                  
                  <div class="g-recaptcha-v2" data-sitekey="6Lc4HfcpAAAAALC00kYgDX7qNYTaP31z9KL20aZT"
                    data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
                  <ValidationMessage errors={validation.errors.recaptcha2} /> 
              </fieldset>  

              { validation.errors.hasErrors && !validation.acknowledged &&
                <Modal 
                  panel 
                  hideClose
                  error
                  icon={AlertIcon}
                  alt="Warning sign"
                  onDismiss={onDismissModal}
                  onConfirm={onDismissModal}
                  title="Oops">
                  <ValidationSummary 
                    modal
                    defaultMessage="Please check you have entered all fields correctly."
                    errors={validation.errors}
                    handlePropertyErrors={['RecaptchaToken']}
                  />
                  { !validation.errors.globalErrors.length ? (
                      <Button title="Try again" onClick={onDismissModal} />
                    ) : (
                      <Button title="Back to homepage" href="/" />
                    )
                  }
                </Modal>
              }
            </Form>

            </>
           ) : (
            <>
              <h1 className="type-h1">That was a chillingly good choice!</h1>
              <p className="form__intro form__intro--wide">
              Now keep an eyeball out for an email on how to redeem your voucher... And don't forget, you still have the chance to win a spooky trip to one of the UK's most haunted cities! </p>
              <SocialButtons
              hasImages
              />
              <p>Follow us to see where we'll set the juice loose next.</p> 
              <Promo choice/>
            </>
          )
        }
        
      </div>
    </section>

    {!sent && 
    <Promo claim/>
    }

    </>
  );
}