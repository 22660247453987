import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';

import './Video.scss';

export default (props) => {
  let currentVideo = useRef();
  let [isPlaying, setIsPlaying] = useState(false);
  let [videoUrl, setVideoUrl] = useState("");
  let [hasMarketingCookies, setHasMarketingCookies] = useState(false);


  const handleClickPreview = (e) => {
    var hasYoutubeCookies = reviewMarketingCookies();
    if(hasYoutubeCookies){
      setVideoUrl(`https://www.youtube.com/watch?v=${props.src}`);
      setIsPlaying(true);
      setHasMarketingCookies(true);
    } else {
      setHasMarketingCookies(false);
      setIsPlaying(true);
    }
  }

  function reviewMarketingCookies() {
    let OnetrustActiveGroups = window.dataLayer.filter(function (entry) {
      return entry.OnetrustActiveGroups;
    }).map((p) => ( p.OnetrustActiveGroups ));
    //C0004 is the value for marketing/youtube cookies on onetrust
    let youtubeCookies = OnetrustActiveGroups.find((element) => element.indexOf("C0004") > -1);
    return youtubeCookies !== undefined ? true : false;
  }


  return (<>
    <div className={"video " + (isPlaying ? "playing":"") + (isPlaying && !hasMarketingCookies ? " video--error" : "")}>
      <button type="button" onClick={handleClickPreview} className='video__playbtn' title="Play Button" >
        <img src={props.poster} />
        
      </button>
      {
        isPlaying && hasMarketingCookies ? (
          <ReactPlayer 
            className='video__react-player optanon-category-C0004'
          // light={props.poster}
            url={videoUrl}
            width='100%'
            height='100%'
            playing={isPlaying}
            //autoplay="true"
            controls
            ref={currentVideo}
            //onClickPreview={handleClickPreview}
          />
        ) : ( isPlaying && !hasMarketingCookies &&
          <div className='video__message'>
            <div className='type-h2'>Content is blocked</div>
            <p>To view this content, please update your consent preference for targeting cookies. If you have given your consent just refresh the video.</p>
            <div className='video__message__ctas'>
              <Link lozenge lozengeSmall to="/cookie-policy" title="Cookie Policy" >Cookie Policy</Link>
              <Button type="button" title="Refresh" onClick={handleClickPreview} smallButton />
            </div>
          </div>
        )
      }
     
    </div>
  </>
  )
} 