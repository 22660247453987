let loadedPromise;
let isLoaded = false;
let registeredComponents = [];

function ensureScriptLoaded(captchaSettings) {

    if (isLoaded || !captchaSettings.isEnabled) {
        isLoaded = true;
        loadedPromise = new Promise(resolve => resolve());
    }

    loadedPromise = new Promise((resolve, reject) => {

        let head = document.getElementsByTagName('head')[0];
        let js = document.createElement('script');

        js.id = 'google-recaptcha-v3';
        js.src = `https://www.google.com/recaptcha/api.js?render=${captchaSettings.publicKey}`;
        js.onload = () => {
            window.grecaptcha.ready(() => {
                isLoaded = true;
                resolve();
            });
        }
        js.onerror = reject;
        
        head.appendChild(js);
    });
}

function disable() {

    if (isLoaded) {
        doDisable();
    } else {
        loadedPromise.then(doDisable);
    }


    function doDisable() {
        registeredComponents.pop();

        if (registeredComponents.length) return;

        // Display none will also disable recaptcha.
        setBadgeDisplayStyle('none');
    }
}

function enable(capchaSettings) {

    if (isLoaded) {
        doEnable();
    } else {
        ensureScriptLoaded(capchaSettings);
        loadedPromise.then(doEnable);
    }

    function doEnable() {
        registeredComponents.push(1);

        // Revert display style
        setBadgeDisplayStyle('block');
    };
}

function setBadgeDisplayStyle(style) { 
    let $grecaptcha = document.querySelector('.grecaptcha-badge');
    if ($grecaptcha) {
        $grecaptcha.style.display = style;
    }
}

function getToken(captchaSettings, actionName) {
    
    if (!captchaSettings.isEnabled) {
        return new Promise(resolve => resolve('Disabled'));
    }
    else if (isLoaded) {
        return doGetToken();
    } else {
        return loadedPromise.then(doGetToken);
    }

    function doGetToken() {
        return new Promise((resolve) => {

            window.grecaptcha
                .execute(captchaSettings.publicKey, { action: actionName })
                .then(resolve);
        });
    }
}

export default {
    getToken,
    enable,
    disable
}