import React from 'react';
import './Validation.scss';

export default (props) => {
  if (!props.errors || !props.errors.length) {
    return null;
  }

  if (props.errors.length > 1) {
    return (
      <ul className="validation-message">
        {props.errors.map((error, index) => <li key={index} className="validation-message__item">{error}</li>)}
      </ul>
    );
  }

  return (
    <div className="validation-message">{props.errors[0]}</div>
  );
}
