import React from 'react';

const CompetitionEntryContext = React.createContext();
const DEFAULT_STATE = {
    barcode: null,
    batchcode: null,
    productType: null,
    result: null,
    prize: null,
    email: null
};

const CompetitionEntryProvider = ({children}) => {
    const [state, setState] = React.useState({...DEFAULT_STATE});

    return (
        <CompetitionEntryContext.Provider value={[state, setState]}>
            {children}
        </CompetitionEntryContext.Provider>
    );
};

export default CompetitionEntryContext;
export { CompetitionEntryProvider, CompetitionEntryContext, DEFAULT_STATE };