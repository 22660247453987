import React from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";

//beetlejuice images streaming launch
import keyvis_beetlejuice_dt_new from 'assets/images/campaign/keyvis/keyvis_beetlejuice_dt--new.png';
import keyvis_beetlejuice_dt_new_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_dt@2x--new.png';
import keyvis_beetlejuice_tab_new from 'assets/images/campaign/keyvis/keyvis_beetlejuice_tab--new.png';
import keyvis_beetlejuice_tab_new_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_tab@2x--new.png';
import keyvis_beetlejuice_mob_new from 'assets/images/campaign/keyvis/keyvis_beetlejuice_mob--new.png';
import keyvis_beetlejuice_mob_new_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_mob@2x--new.png';

//beetlejuice images post film launch
import keyvis_beetlejuice_dt from 'assets/images/campaign/keyvis/keyvis_beetlejuice_dt.png';
import keyvis_beetlejuice_dt_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_dt@2x.png';
import keyvis_beetlejuice_tab from 'assets/images/campaign/keyvis/keyvis_beetlejuice_tab.png';
import keyvis_beetlejuice_tab_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_tab@2x.png';
import keyvis_beetlejuice_mob from 'assets/images/campaign/keyvis/keyvis_beetlejuice_mob.png';
import keyvis_beetlejuice_mob_2x from 'assets/images/campaign/keyvis/keyvis_beetlejuice_mob@2x.png';

//text images
import keyvis_text_dt from 'assets/images/campaign/keyvis/keyvis_text_dt.png';
import keyvis_text_dt_2x from 'assets/images/campaign/keyvis/keyvis_text_dt@2x.png';
import keyvis_text_tab from 'assets/images/campaign/keyvis/keyvis_text_tab.png';
import keyvis_text_tab_2x from 'assets/images/campaign/keyvis/keyvis_text_tab@2x.png';
import keyvis_text_mob from 'assets/images/campaign/keyvis/keyvis_text_mob.png';
import keyvis_text_mob_2x from 'assets/images/campaign/keyvis/keyvis_text_mob@2x.png';

//sweet images
import keyvis_sweets_dt from 'assets/images/campaign/keyvis/keyvis_sweets_dt.png';
import keyvis_sweets_dt_2x from 'assets/images/campaign/keyvis/keyvis_sweets_dt@2x.png';
import keyvis_sweets_tab from 'assets/images/campaign/keyvis/keyvis_sweets_tab.png';
import keyvis_sweets_tab_2x from 'assets/images/campaign/keyvis/keyvis_sweets_tab@2x.png';


import FadeIn from 'components/FadeIn/FadeIn';

import './KeyVisual.scss';


export default () => {

  const classes = {
    "keyvis": true
  }

  let parallaxMargin = 10;
  let parallaxPercentage = 80;
  let offsetParPercentage = 90;
  let strength = 100;
  var isDesk = window.matchMedia("only screen and (min-width: 640px)").matches;

  if (isDesk) {
    parallaxMargin = 0;
    strength = 100;
    parallaxPercentage = 140;
    offsetParPercentage = 140;
  }

  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  const currentDate = new Date();
  const targetDate = new Date('2024-10-20T23:59:59');

  return (
    <div className={classList(classes)}>
      <Parallax
        strength={strength}
        renderLayer={percentage => (
          <div className='keyvis-wrapper'>
            <div className="keyvis__bg"
              style={{
                transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
              }}
            ></div>

            <FadeIn className='keyvis__headline'>
              <picture>
                <source type="image/png" srcSet={keyvis_text_dt_2x + " 2x, " + keyvis_text_dt + " 1x"} media="(min-width:992px)" />
                <source type="image/png" srcSet={keyvis_text_tab_2x + " 2x, " + keyvis_text_tab + " 1x"} media="(min-width:640px)" />
                <img src={keyvis_text_mob} srcSet={keyvis_text_mob_2x + " 2x, " + keyvis_text_mob + " 1x"} alt="A chance to win a spooky trip plus over one thousand prizes" />
              </picture>
            </FadeIn>

            {currentDate >= targetDate ? (
               <picture>
               <source type="image/png" srcSet={keyvis_beetlejuice_dt_new_2x + " 2x, " + keyvis_beetlejuice_dt_new + " 1x"} media="(min-width:992px)" />
               <source type="image/png" srcSet={keyvis_beetlejuice_tab_new_2x + " 2x, " + keyvis_beetlejuice_tab_new + " 1x"} media="(min-width:640px)" />
               <img src={keyvis_beetlejuice_mob_new} srcSet={keyvis_beetlejuice_mob_new_2x + " 2x, " + keyvis_beetlejuice_mob_new + " 1x"} alt="Beetlejuice Beetlejuice: Buy or rent."
                 className='keyvis__beetlejuice' />
               </picture>
            ) : (
              <picture>
              <source type="image/png" srcSet={keyvis_beetlejuice_dt_2x + " 2x, " + keyvis_beetlejuice_dt + " 1x"} media="(min-width:992px)" />
              <source type="image/png" srcSet={keyvis_beetlejuice_tab_2x + " 2x, " + keyvis_beetlejuice_tab + " 1x"} media="(min-width:640px)" />
              <img src={keyvis_beetlejuice_mob} srcSet={keyvis_beetlejuice_mob_2x + " 2x, " + keyvis_beetlejuice_mob + " 1x"} alt="Beetlejuice Beetlejuice: Only in Cinemas."
                className='keyvis__beetlejuice' />
              </picture>
            )}

            <picture className="sweets">
              <source type="image/png" srcSet={keyvis_sweets_dt_2x+ " 2x, " + keyvis_sweets_dt + " 1x"} media="(min-width:992px)" />
              <img src={keyvis_sweets_tab} srcSet={keyvis_sweets_tab_2x + " 2x, " + keyvis_sweets_tab + " 1x"} alt="Maynards Spooky Gums and Maynards Liquorice Mix."
                className='keyvis__sweets' />
            </picture>
          </div>
      
        )}
      />

    </div>

  )
}