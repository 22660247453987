import api from './api';

// init
attachGlobalHandler();

function attachGlobalHandler() {
    window.addEventListener('error', handleGlobalError);
    window.addEventListener('unhandledrejection', handleUnhandledPromiseRejection);
}
    
function handleGlobalError(msg, src, lineno, colno, error) { 

    logError(error || msg, {
        msg,
        src,
        lineno,
        colno,
        error
    })
}
    
function handleUnhandledPromiseRejection(event) { 
    logError(event.reason, event);
}

/* Public */

// function logInfo(message, context) {
//     // not yet used
// }

// function logWarning(message, context) {
//     // not yet used
// }

async function logError(error, context) {
    if (error.message) {
        context = context || {};
        context.error = error;
    }
    try {
        await api.post('errors', {
            message: error.message || error,
            url: window.location.href,
            clientData: context
        });
    } catch (error) {
        console.log('error logging error', error);
    }
}

export default {
    logError,
    // logWarning,
    // logInfo
}