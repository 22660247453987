import React from 'react';

const SettingsContext = React.createContext();
const defaultState = {
    isLoading: true,
    contentHeight: 0,
    windowHeight: 0
};


const SettingsProvider = ({children}) => {

    const [state, setState] = React.useState({...defaultState});

    return (
        <SettingsContext.Provider value={[state, setState]}>
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsContext;
export { SettingsProvider, SettingsContext };