import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button'; 
import './ToolTip.scss';

export default (props) => {
  const [showTooltipModal, setShowTooltipModal] = useState(false);

  const onDismissModal = () => {
    setShowTooltipModal(false);
  }

  const onOpenModal = () => {
    setShowTooltipModal(true);
  }

  return (
    <div className="tooltip">
      <Button className="tooltip__cta" title="i" onClick={onOpenModal} />
      { showTooltipModal && 
        <Modal 
          className="modal--tooltip"
          panel 
          panelBackground
          onDismiss={onDismissModal}
          onConfirm={onDismissModal}
          title={props.title}>
          <p>{ props.content }</p>
        </Modal>
     }
    </div>
   
  );
}
