import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Promo from 'components/Promotion/Promo';
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';



export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#', '').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index &&
      index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key = `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Maynards Bassetts - Beetlejuice</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper layout-wrapper--lines">
      <div className="layout-container layout-container--slim layout-container--center layout-container--bottom-space">

        {<div className="faqs">
          <div className="faqs__section">
            <h1 className="type-h1">FAQ<span className="lowercase">s</span></h1>
            <h2 className="type-h2">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1. Who is eligible to enter?",
                answer: `
                <p>
                This Promotion is open to residents of the United Kingdom including the Channel Islands and the Isle of Man aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion.</p>`
              }, {
                question: "2. When can I enter?",
                answer: `
                <p>
                  Enter between 00:01 BST on the 1 August 2024 and 23:59 GMT on the 30 November 2024 inclusive.
                </p>
              `}, {
                question: "3. How do I enter?",
                answer: `
                <p>
                Purchase a pack of either Limited Edition Maynards Bassets Liquorice Mix 130g or Limited Edition Maynards Bassets Spooky Gums 130g (each a “Promotional Pack”), scan the QR code or visit <a href="https:maynardsbassetts.co.uk/" target="_blank" rel="noopener noreferrer">www.maynardsbassetts.co.uk</a>. Then, complete the online form and provide: your details (full name and email address), the retailer the Promotional Pack was purchased from, and the barcode and/or the batch code. You will then be entered into the 'Other Retailers' Prize Draw. For Sainsbury's customers, you will also be entered into the relevant Retailer Exclusive Prize Draw. All valid entries will also be submitted into a Winning Moments promotion.                </p>
              `}, {
                question: "4. How many times can I enter?",
                answer: `
                <p>
                Maximum of 1 entry is permitted per person per day in the Winning Moments Promotion, with a maximum of 20 entries throughout the entire Promotion Period.                 </p>  
                <p>
                Maximum of 2 entries are permitted per person per applicable Prize Draw. Upon entering the Prize Draws for Sainsbury's entrants will automatically be entered into the Other Retailer's Prize Draw which will count as the maximum 2 entries per day.                </p>
              `},
              {
                question: "5. How many prizes could I win?",
                answer: `
                <p>
                  No person may win more than 1 x Winning Moments Prize and 1 x Prize Draw Prize.
                </p>
              `}, {
                question: "6. Can I enter the prize draw without purchasing Maynards Bassets?",
                answer: `
                <p>
                  No sorry! Purchase is necessary. Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their Prize. 
                </p>
              `},
              {
                question: "7. The QR code won't work?",
                answer: `
                <p>
                  Please visit <a href="https:maynardsbassetts.co.uk/" target="_blank" rel="noopener noreferrer">www.maynardsbassetts.co.uk</a> and complete the online form to enter.
                </p>
              `}, {
                question: "8. What constitutes as a receipt?",
                answer: `
                <p>
                  Because entry requires the purchase of Maynards Bassets promotional packs, you'll need the receipt you were given when you purchased your pack. This must state the time & date of purchase, which must pre-date your entry and be within the Promotion Period.  You will be asked to send a photograph of your receipt to claim your prize. 
                </p>
              `}, {
                question: "9. Which products can I buy in order to be eligible to enter?",
                answer: `
                <p>
                  You need to buy any of the following Maynards Bassets products to be entered:
                </p>
                <ul>
                  <li><p>Limited Edition Maynards Bassets Liquorice Mix 130g</p></li>
                  <li><p>Limited Edition Maynards Bassets Spooky Gums 130g</p></li>
                </ul>
              `}
                , {
                question: "10. What could I win?",
                answer: `
                <p>
                  You could win:
                </p>
                <ul>
                  <li><p><strong>Winning Moments Prize:</strong> 1 x £25 Gift Card for either Boots ('Spooky Beauty') or John Lewis ('Haunting Homeware'), subject to the winner's choice</p></li>
                  <li><p><strong>Sainsbury's Exclusive Prize:</strong> There will be 1 Sainsbury's Exclusive Prize winner who will win 1 x trip to York for the winner and a guest, including a two-night hotel stay with breakfast (minimum 3*), a choice between an Evening Ghost Bus Tour or Evening Ghost Walk, and £400 travel allowance and spending money</p></li>
                  <li><p><strong>Other Retailers Prize: </strong> There will be 2 Other Retailers Prize winners. Each winner will win one of the following:</p>
                  <ul>
                    <li><p><strong>London:</strong> One winner will win 1 x trip to London including a two-night spooky hotel stay in London (minimum 3*), one dinner at a spooky restaurant for the winner and 3 guests, return transfers from the winner's home address to the London hotel and £200 spending money</p></li>
                  <li><p><strong>Edinburgh:</strong> One winner will win 1 x trip to Edinburgh for the winner and a guest, including a two-night hotel stay with breakfast (minimum 3*), an Evening Ghost Bus Tour and £400 travel allowance and spending money</p></li>
                  </ul>
                  </li>
                </ul>
              `},
              {
                question: "11. How will winners be selected?",
                answer: `
              <ol type="a">
                <li><p><strong>Winning Moments:</strong> During the Promotion Period, 1,010 “winning moments” (days, hours, minutes, seconds) have been randomly selected by an independent verification service. The first entry on or after that winning moment will be awarded the Prize. If no entry is received between one winning moment to the next, the Prize from that winning moment will be rolled over, creating an additional winning moment. </p></li>
                <li><p><strong>Prize Draws:</strong> 1 provisional winner from each Retailer Prize Pool (Sainsbury's or Other retailers), 3 in total (“provisional winners”) will be randomly selected from all valid entries for each Retailer during the Promotion Period. For your total confidence, the Prize Draws will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Promotion Period.</p></li>
                </ol>
              `}, {
                question: "12. How will I know if I've won?",
                answer: `
               <ol type='a'>
                <li><p><strong>Winning Moments:</strong> The provisional winning entrants will be instantly notified on screen and asked to follow the instructions immediately to claim their Prize.</p></li>
                <li><p><strong>Prize Draws:</strong> Provisional winners will be contacted via the email address provided on entry within 5 working days of Winner Selection and will be asked to provide evidence of identity and eligibility.</p></li>
                </ol>
              `}, {
                question: "13. How do I claim my prize?",
                answer: `
               <ol type='a'>
                <li><p><strong>Winning Moments:</strong> Provisional winners must follow the instructions on screen within 14 days of initial contact. Any claims received that don't meet these requirements or are received more than 14 days after the close of the Promotion Period, will be void and invalid and we reserve the right to return the Prize to the Promotion Winning Moments Prize pool which may be won by someone else during the Promotion Period.</p></li>
                <li><p><strong>Prize Draws:</strong> Provisional winners will be asked to reply to the email notification and provide evidence of identity and eligibility. If a provisional winner does not respond to the initial contact within 14 days, we reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p></li>
                </ol>
              `},
              {
                question: "14. If I win a gift card how will I receive it?",
                answer: `
                 <p>Once winners are validated our team will send you an e-Gift Card via email.</p>
              `}, {
                question: "15. I have not received my gift card – what do I do?",
                answer: `
                <p>Fulfilment of the gift cards will take place within 28 days of acceptance and confirmation of eligibility. In the unlikely event that a winner has not received their gift card by this time, the winner must inform the Promoter by emailing <a href="mailto:maynardsbassetts@promowinners.com" target="_blank" rel="noopener noreferrer">maynardsbassetts@promowinners.com</a>.</p>
              `}
                , {
                question: "16. If I win a trip when can I travel?",
                answer: `
                <p>
                  The trip can be taken from the 10th January 2025 onwards. The trip must be booked and taken by the 30th November 2025 (subject to availability). At least 60 days' advance notice of preferred date of stay must be given and the Prize cannot be taken during the excluded dates. Please see <a href="/terms-and-conditions">T&C's</a> for full details of your prize and any restrictions.
                </p>
              `}
                , {
                question: "17. Can I exchange my prize for cash?",
                answer: `
                <p>
                  Unfortunately not, all prizes are as specified. Please see <a href="/terms-and-conditions">T&C's</a> for full details of your prize and any restrictions.
                </p>
              `},
              {
                question: "18. I've submitted my receipt for validation, why haven't I heard anything?",
                answer: `
                <p>
                  We will aim to get back to you within 3 business days. If you have not heard from us by this time, please resend your email as we may not have received it.
                </p>
              `},
              {
                question: "19. Where can I find the full terms and conditions of the promotion?",
                answer: `
                <p>
                  Please visit <a href="https:maynardsbassetts.co.uk/" target="_blank" rel="noopener noreferrer">www.maynardsbassetts.co.uk</a>.
                </p>
              `},
              {
                question: "20. My question hasn't been answered in the FAQs",
                answer: `
                <p>
                  If you still have further questions regarding the promotion, please email us at <a href="mailto:maynardsbassetts@promowinners.com" target="_blank" rel="noopenernoreferrer">maynardsbassetts@promowinners.com</a>.
                </p>
              `}
              ])
            }
          </div>
        </div>}
      </div>
    </section>
    <Promo nobugs />
  </>;
}