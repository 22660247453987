import React, { useEffect } from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlock.scss';

const PrizeBlock = (props) => {

  const mainClassMap = {
    "prize-block": true
  };

  const imageClassMap = {
    "prize-block__image": true,
    "prize-block__image--contain": props.containImage
  };

  const classMap = {
    "show": props.show
  }

  return (
    (
      <article className={classList(mainClassMap)}>
        {props.image &&
          <div className={classList(imageClassMap)}>
            <picture>
              { props.imageLarge &&
                <source media="(min-width: 640px)" src={props.image} srcSet={props.imageLarge + " 2x, " + props.image + " 1x"}/>
              }
              <img src={props.imageMob} srcSet={props.imageMobLarge + " 2x, " + props.imageMob + " 1x"}alt={props.alt || props.title} />
            </picture>
          </div>
        }      
      </article>
    )
  )
}

export default PrizeBlock;
