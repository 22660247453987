import React, { useEffect, useState, useRef } from 'react';
import classList from 'react-classlist-helper';
import FocusTrap from 'focus-trap-react';
import Link from 'components/Link/Link';
import Logo from 'assets/images/themes/maynards/maynards-logo.svg';

import analyticsService from 'services/analyticsService';

import './Header.scss';

let lastScroll = 0;
let direction = 0;
let menuOpen = false;
let ticking = false;

export default function (props) {
  const [hide, setHide] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const menuStateInput = useRef();

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!navOpen) {
      document.getElementsByTagName('body')[0].removeAttribute("style");
    } else {
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
    }
  }, [navOpen]);

  useEffect(() => {
    if (menuStateInput && menuStateInput.current) {
      menuStateInput.current.addEventListener("keyup", onKeyUp);
      return () => menuStateInput.current.removeEventListener("keyup", onKeyUp);
    }
  }, []);

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll();
        ticking = false;
      });
      ticking = true;
    }
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const handleScroll = async () => {
    let scroll = getScroll();
    direction = (scroll > lastScroll) ? 1 : -1;

    if (menuStateInput.current) {
      menuOpen = await getMenuState();
    }

    setHide(direction === 1 && !menuOpen && scroll > 150);
    lastScroll = scroll;
  }

  const getMenuState = async () => {
    return menuStateInput.current.checked;
  }

  const getScroll = () => {
    return window.scrollY || window.pageYOffset;
  }

  const handleClick = () => {
    menuStateInput.current.click();
  }


  const onInputChange = (e) => {
    setNavOpen(e.currentTarget.checked);

    if(e.currentTarget.checked) {
      analyticsService.logEvent("siteNavigationLink", "NavigationMenuButton", {
        action: "OpenNavigationMenu",
        label: "open menu"
      });
    } else {
      analyticsService.logEvent("siteNavigationLink", "NavigationMenuButton", {
        action: "CloseNavigationMenu",
        label: "close menu"
      });
    }
  }


  const classMap = {
    "header": true,
    "header--hide": hide,
    "header--navopen": navOpen,
    [props.className]: !!props.className,
  };

	return (
		<header className={classList(classMap)}>
       
			{
        (props.is500 !== undefined && props.is500) ? (
          <Link className="header__logo" to="/">
            <img src={Logo} className="header__svg" alt="Maynards Bassetts" />
          </Link>
        ) : (
          <Link route className="header__logo" to="/">
            <img src={Logo} className="header__svg" alt="Maynards Bassetts" />
          </Link>
        )
      }
      
      { props.enableMenu && 
       
          <FocusTrap active={navOpen}>
             <nav className="header__nav">
            <div className="header__menuToggle">
              <input type="checkbox" ref={menuStateInput} onChange={onInputChange} />
              <span className='header__menuToggle__focus'></span>
              <div className="header__menuToggle__container">
                <span className='header__menuToggle__span'></span>
                <span className='header__menuToggle__span'></span>
                <span className='header__menuToggle__span'></span>
              </div>
              <div className="header__menu">
                <ul className="header__menu__nav">
                  <li><Link 
                      analytics={{evnt: "siteNavigationLink", category: "HomeLink", action: "OpenHomeLink"}}
                      to="/"
                      route
                      title="Home"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        Home
                  </Link></li>

                  <li><Link 
                      analytics={{evnt: "siteNavigationLink", category: "FAQsLink", action: "OpenFAQsLink"}}
                      to="/faqs"
                      route
                      title="FAQs"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        FAQ<span className="lowercase">s</span>
                  </Link></li>

                  <li><Link 
                      analytics={{evnt: "siteNavigationLink", category: "TandCsLink", action: "OpenTandCsLink"}}
                      to="/terms-and-conditions"
                      route
                      title="Terms and Conditions"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        T&amp;C<span className="lowercase">s</span>
                  </Link></li>

                  <li><Link 
                      analytics={{evnt: "siteNavigationLink", category: "ContactLink", action: "OpenContactLink"}}
                      href="https://contactus.mdlzapps.com/sweetsoundsofsummer.com/contact/en-UK/"
                      title="Contact"
                      target="_blank"
                      className="header__link"
                      cancelFocus={!navOpen} 
                      onClick={handleClick} >
                        Contact
                  </Link></li>

                </ul>
              </div>
            </div>
            <div className="header__overlay" onClick={handleClick}></div>
            </nav>
          </FocusTrap>
        
      }
		</header>
	);
}