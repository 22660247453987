import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import PrizeBlockDouble from 'components/Prize/PrizeBlockDouble';
import Step from 'components/Step/Step';
import SocialButtons from 'components/SocialButtons/SocialButtons';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';


import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How2 from 'assets/images/campaign/how-2-1.png';
import How2_2x from 'assets/images/campaign/how-2-1@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How4_2x from 'assets/images/campaign/how-4@2x.png';

import doublePack from 'assets/images/campaign/products/doublepack.png';
import doublePack2x from 'assets/images/campaign/products/doublepack@2x.png';
import doublePackTab from 'assets/images/campaign/products/doublepackTab.png';
import doublePackTab2x from 'assets/images/campaign/products/doublepackTab@2x.png';

import mixpack from 'assets/images/campaign/products/mixpack.png';
import mixpack2x from 'assets/images/campaign/products/mixpack@2x.png';
import mixpackDesk from 'assets/images/campaign/products/mixpack_desk.png';
import mixpackDesk2x from 'assets/images/campaign/products/mixpack_desk@2x.png';

import spookypack from 'assets/images/campaign/products/spookypack.png';
import spookypack2x from 'assets/images/campaign/products/spookypack@2x.png';
import spookypackDesk from 'assets/images/campaign/products/spookypack_desk.png';
import spookypackDesk2x from 'assets/images/campaign/products/spookypack_desk@2x.png';

//PRIZES
import PrizeTierOne from 'assets/images/campaign/prizes/prize_top_mob.png';
import PrizeTierOne2x from 'assets/images/campaign/prizes/prize_top_mob@2x.png';
import PrizeTierOneDesk from 'assets/images/campaign/prizes/prize_top_desk.png';
import PrizeTierOneDesk2x from 'assets/images/campaign/prizes/prize_top_desk@2x.png';

import PrizeTierTwoHome from 'assets/images/campaign/prizes/prize_house_mob.png';
import PrizeTierTwoHome2x from 'assets/images/campaign/prizes/prize_house_mob@2x.png';
import PrizeTierTwoHomeDesk from 'assets/images/campaign/prizes/prize_house_desk.png';
import PrizeTierTwoHomeDesk2x from 'assets/images/campaign/prizes/prize_house_desk@2x.png';

import PrizeTierTwoMakeup from 'assets/images/campaign/prizes/prize_makeup_mob.png';
import PrizeTierTwoMakeup2x from 'assets/images/campaign/prizes/prize_makeup_mob@2x.png';
import PrizeTierTwoMakeupDesk from 'assets/images/campaign/prizes/prize_makeup_desk.png';
import PrizeTierTwoMakeupDesk2x from 'assets/images/campaign/prizes/prize_makeup_desk@2x.png';

import analyticsService from 'services/analyticsService';

import './Home.scss';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsNotStarted = compState === "NotStarted";
  let competitionIsMopUp = compPhaseId === "mop-up";
  let competitionIsClosed = compState === "Closed";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

 
  const handleEnterNow = async () => {
    analyticsService.logEvent("enterCompetition", "ProofOfPurchase", {
      action: "OpenProofOfPurchaseModal",
      label: "open modal"
    });
    setShowEntryModal(true);
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = (title) => {

    return <Button
      wide
      type="submit"
      title={title ? title : "Enter"}
      onClick={handleEnterNow}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
        {
      
            <div className="home__intro home__intro--wide">
              <p className='type-h1 type-h1--white'>It's showtime</p>
              <p className='type-h2 type-h2--white'>The ghost with the most is back</p>
              
              <div className="home__intro-body">
                <p>
                  <span>
                  To celebrate the highly anticipated new movie, <em>Beetlejuice Beetlejuice</em>, in cinemas September 6, Maynards Bassetts are bringing you two deliciously dark limited-edition treats! There is also a chance to WIN an array of spine-chilling prizes!
                  </span>
                </p>
                <p>
                You could bag yourself a dead good trip to one of the UK's most haunted cities. Enjoy a two-night hotel stay including travel, breakfast, a spooky experience and £200 spending money.                </p>
                <p>
                But that's not all… Set the juice loose with over 1,000 instant prizes up for grabs. Including £25 homeware vouchers to deck out your digs, or £25 beauty vouchers to treat yourself to some menacing makeup for a big night at the cinema.                </p>
                
                <img className="home__intro__image" src={spookypackDesk} srcSet={spookypackDesk2x + " 2x, " + spookypackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />
                <img className="home__intro__image home__intro__image--mix" src={mixpackDesk} srcSet={mixpackDesk2x + " 2x, " + mixpackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />

              </div>
              <div className="home__cta">
                {renderEntryCta("Enter")}
              </div>
            </div>
        }
          
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro">
            <p className='type-h1'>It's not showtime yet</p>
            <p className='type-h2'>Come back from 1 August 2024 to enter and set the juice loose</p>
            <div className="home__intro__image-container">
              <picture>
                <source media="(min-width: 640px)" srcSet={doublePackTab2x + " 2x, " + doublePackTab + " 1x"}  alt="Maynards Spooky Gums and Maynards Liquorice Mix" />        
                <img className="home__intro__double-image" src={doublePack} srcSet={doublePack2x + " 2x, " + doublePack + " 1x"}  alt="Maynards Spooky Gums and Maynards Liquorice Mix" />        
              </picture>
            </div>
            
            <img className="home__intro__image" src={spookypackDesk} srcSet={spookypackDesk2x + " 2x, " + spookypackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />

            <img className="home__intro__image home__intro__image--mix" src={mixpackDesk} srcSet={mixpackDesk2x + " 2x, " + mixpackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />
          
          </div>

        </>

      case 'Closed':
        return <>
          <div className="home__intro home__intro--wide home__intro--closed">
            <p className='type-h1'>R.I.P</p>
            <p className='type-h2'>Sorry, this promotion is now in the afterlife.</p>
            <div className="home__intro__image-container">
              <picture>
                <source media="(min-width: 640px)" srcSet={doublePackTab2x + " 2x, " + doublePackTab + " 1x"}  alt="Maynards Spooky Gums and Maynards Liquorice Mix" />        
                <img className="home__intro__double-image" src={doublePack} srcSet={doublePack2x + " 2x, " + doublePack + " 1x"}  alt="Maynards Spooky Gums and Maynards Liquorice Mix" />        
              </picture>
            </div>
            
            <img className="home__intro__image" src={spookypackDesk} srcSet={spookypackDesk2x + " 2x, " + spookypackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />

            <img className="home__intro__image home__intro__image--mix" src={mixpackDesk} srcSet={mixpackDesk2x + " 2x, " + mixpackDesk + " 1x"} alt="Maynards Bassetts Beetlejuice" />
            <SocialButtons/>

            <p>Follow us to see where we'll set the juice loose next.</p>
          </div>

        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Maynards Bassetts - Beetlejuice</title>
      </Helmet>
      {competitionIsOpen &&
        <>
          <CodeEntry
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push("/registration") } 
          />
        </>
      }

      <h1 className="visually-hidden">Maynards Bassetts - Beetlejuice</h1>

      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual />
          </div>
        </div>
      </section>

      
      <section className={"layout-wrapper home__entrybar relative " +  (!competitionIsOpen ? "layout-wrapper--goo-black bg-white" : "bg-black ")}>
        <div className={"layout-container layout-container--center " + (competitionIsOpen ? "layout-container--bottom-space"  : "")}>

          {renderIntroMessage(compState)}
          
        </div>
      </section>

        
        {(competitionIsOpen) &&
          <section className="layout-wrapper layout-wrapper--goo-black prize-block-section bg-primary">
            <div className="layout-container layout-container--center layout-container--wide">
              <h2 className="type-h1 type-h1--white">prizes</h2>

              <div className="home__prize-blocks ">      
                  <PrizeBlock
                    imageMob={PrizeTierOne}
                    imageMobLarge={PrizeTierOne2x}
                    image={PrizeTierOneDesk}
                    imageLarge={PrizeTierOneDesk2x}
                    alt="Thrilling 2 night city break"
                  />  
              </div>

              <div className="home__prize-blocks home__prize-blocks--double">
              
                    <PrizeBlock
                      imageMob={PrizeTierTwoMakeup}
                      imageMobLarge={PrizeTierTwoMakeup2x}
                      image={PrizeTierTwoMakeupDesk}
                      imageLarge={PrizeTierTwoMakeupDesk2x}
                      alt="£25 Spooky makeup voucher"
                    />

                    <PrizeBlock
                      imageMob={PrizeTierTwoHome}
                      imageMobLarge={PrizeTierTwoHome2x}
                      image={PrizeTierTwoHomeDesk}
                      imageLarge={PrizeTierTwoHomeDesk2x}               
                      alt="£25 Haunted house makeover voucher"
                   />

              </div>
            </div>
          </section>
        }

      {
        (competitionIsOpen) &&
        <section className="layout-wrapper layout-wrapper--goo-purple home__steps-section" id="how-to-play">
          <div className="layout-container layout-container--center ">
            <h2 id="howitworks" className="type-h1">It's dead easy to enter</h2>
            <div className="home__steps home__steps--wide">
            {
                      renderSteps([
                        { number: 1, description: "Buy a pack of our deliciously dark Maynards Bassetts Spooky Gums or Liquorice Mix limited editions.", image: How1, image2x: How1_2x, alt: "Maynard Bassetts products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>See full product list here</a></> },
                        { number: 2, description: "Enter the barcode/batch code and then your details.", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Maynard Bassetts product pack" },
                        { number: 3, description: "Say 'Beetlejuice' 3 times into your microphone… go on, just say it.", image: How3, image2x: How3_2x, alt: "Say Beetlejuice, beetlejuice, beetlejuice" },
                        { number: 4, description: "You're in the draw for a spooky weekend! Plus find out instantly if you've won a £25 gift card.", image: How4, image2x: How4_2x, alt: "A chance to win a spooky trip plus over 1000 prizes." },
                      ])
            }       
                
            </div>
            <div className="home__cta">
              {renderEntryCta("Enter")}
            </div>
          </div>
        </section>
      }

    </div >
  );
}