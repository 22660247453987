import React from "react";
import recaptchaService from 'services/reCaptchaService';

// export default ReCaptcha;

export default (props) => {

    React.useEffect(() => {
        recaptchaService.enable(props.captchaSettings);

        return () => recaptchaService.disable();
    })

    return null;
}