import React from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";


//splat images
import thankyou_splat_dt from 'assets/images/campaign/thankyou-hero/thankyou_splat_dt.png';
import thankyou_splat_dt_2x from 'assets/images/campaign/thankyou-hero/thankyou_splat_dt@2x.png';
import thankyou_splat_tab from 'assets/images/campaign/thankyou-hero/thankyou_splat_tab.png';
import thankyou_splat_tab_2x from 'assets/images/campaign/thankyou-hero/thankyou_splat_tab@2x.png';
import thankyou_splat_mob from 'assets/images/campaign/thankyou-hero/thankyou_splat_mob--alternative.png';
import thankyou_splat_mob_2x from 'assets/images/campaign/thankyou-hero/thankyou_splat_mob--alternative@2x.png';

//sweet images
import thankyou_sweets_dt from 'assets/images/campaign/thankyou-hero/thankyou_sweets_dt.png';
import thankyou_sweets_dt_2x from 'assets/images/campaign/thankyou-hero/thankyou_sweets_dt@2x.png';
import thankyou_sweets_tab from 'assets/images/campaign/thankyou-hero/thankyou_sweets_tab.png';
import thankyou_sweets_tab_2x from 'assets/images/campaign/thankyou-hero/thankyou_sweets_tab@2x.png';
import thankyou_sweets_mob from 'assets/images/campaign/thankyou-hero/thankyou_sweets_mob--alternative.png';
import thankyou_sweets_mob_2x from 'assets/images/campaign/thankyou-hero/thankyou_sweets_mob--alternative@2x.png';

//Beetlejuice images pre sept 6th
import thankyou_beetlejuice_dt_alt from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_dt--rent.png';
import thankyou_beetlejuice_dt_2x_alt from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_dt--rent@2x.png';
import thankyou_beetlejuice_tab_alt from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_tab--rent.png';
import thankyou_beetlejuice_tab_2x_alt from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_tab--rent@2x.png';

//Beetlejuice images post sept 6th
import thankyou_beetlejuice_dt from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_dt.png';
import thankyou_beetlejuice_dt_2x from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_dt@2x.png';
import thankyou_beetlejuice_tab from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_tab.png';
import thankyou_beetlejuice_tab_2x from 'assets/images/campaign/thankyou-hero/thankyou_beetlejuice_tab@2x.png';



import './ThankYouHero.scss';


const ThankYouHero = (props) => {

  const classes = {
    "thankyou-hero": true
  }

  let parallaxMargin = 10;
  let parallaxPercentage = 0;
  let offsetParPercentage = 0;
  let strength = 100;
  var isDesk = window.matchMedia("only screen and (min-width: 992px)").matches;
  var plxDissabled = true;

  if (isDesk) {
    parallaxMargin = 0;
    strength = 100;
    parallaxPercentage = 140;
    offsetParPercentage = 140;
    plxDissabled = false;
  }

  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  const currentDate = new Date();
  const targetDate = new Date('2024-10-20T23:59:59');

  return (
    <div className={classList(classes)}>

      <div className='thankyou-hero-wrapper'>
      <div className={currentDate >= targetDate ? "thankyou-hero__bg" : "thankyou-hero__bg thankyou-hero__bg--alt"}></div>

      {currentDate >= targetDate ? (
        <picture>
        <source type="image/png" srcSet={thankyou_beetlejuice_dt_2x_alt + " 2x, " + thankyou_beetlejuice_dt_alt + " 1x"} media="(min-width:992px)" />
        <img className="thankyou-hero__beetle" src={thankyou_beetlejuice_tab_alt} srcSet={thankyou_beetlejuice_tab_2x_alt + " 2x, " + thankyou_beetlejuice_tab_alt + " 1x"} alt="Beetlejuice"/>
        </picture>
      ) : (
        <picture>
        <source type="image/png" srcSet={thankyou_beetlejuice_dt_2x + " 2x, " + thankyou_beetlejuice_dt + " 1x"} media="(min-width:992px)" />
        <img className="thankyou-hero__beetle" src={thankyou_beetlejuice_tab} srcSet={thankyou_beetlejuice_tab_2x + " 2x, " + thankyou_beetlejuice_tab + " 1x"} alt="Beetlejuice"/>
        </picture>
      )}


        <Parallax
          strength={strength}
          disabled={plxDissabled}
          renderLayer={percentage => (
              <div className="thankyou-hero__container" style={{
                transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
              }}>
              <picture>
                <source type="image/png" srcSet={thankyou_splat_dt_2x + " 2x, " + thankyou_splat_dt + " 1x"} media="(min-width:992px)" />
                <source type="image/png" srcSet={thankyou_splat_tab_2x + " 2x, " + thankyou_splat_tab + " 1x"} media="(min-width:640px)" />
                <img src={thankyou_splat_mob} srcSet={thankyou_splat_mob_2x + " 2x, " + thankyou_splat_mob + " 1x"} alt="goo"
                  className={props.winner ? 'thankyou-hero__beetlejuice thankyou-hero__beetlejuice--fade' : 'thankyou-hero__beetlejuice'} 
                  />
              </picture>

              {
                props.winner && 
                  <picture>
                  <source type="image/png" srcSet={thankyou_sweets_dt_2x + " 2x, " + thankyou_sweets_dt + " 1x"} media="(min-width:992px)" />
                  <source type="image/png" srcSet={thankyou_sweets_tab_2x + " 2x, " + thankyou_sweets_tab + " 1x"} media="(min-width:640px)" />
                  <img src={thankyou_sweets_mob} srcSet={thankyou_sweets_mob_2x + " 2x, " + thankyou_sweets_mob + " 1x"} alt="Spooky Gums and Liquorice Mix sweets."
                    className='thankyou-hero__sweets' />
                </picture>
             
              }
              </div>
          )}
        />
      </div>
    </div>


  )
}

export default ThankYouHero;