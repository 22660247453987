import React, { useRef, useState } from 'react';
import classList from 'react-classlist-helper';
import useEffectOnce from 'hooks/useEffectOnce';
import './CustomDropdownField.scss';

import analyticsService from 'services/analyticsService';

export default (props) => {
  const [selectedValue, setSelectedValue] = useState({value: "", label: "Please select an option"});
  const [selectedImage, setSelectedImage] = useState("");
  const [isOpened, setIsOpened] =  useState(false);

  const inputRef = useRef();
  const classMap = {
    "custom-dropdown": true,
    "custom-dropdown--error": props.hasErrors,
  };

  function onChange(event) {
    var currVal = event.target.value;
    var eventLabel = event.target.value;

    var probContent = props.options.filter(function (entry) {
      return entry.value === currVal;
    });

    if(probContent[0] !== undefined){
      setSelectedValue(probContent[0]);

      if(probContent[0].image !== undefined && probContent[0].image !== null){
        setSelectedImage(probContent[0].image);
      }

      eventLabel = probContent[0].label;
    }

    document.getElementsByTagName("details")[0].removeAttribute("open");

    
    if(props.selectAnalytics){
      var index = event.nativeEvent.target.selectedIndex;
      var analytics = props.selectAnalytics;

      analyticsService.logEvent(analytics.evnt, analytics.category, {
        action: analytics.action,
        label: eventLabel
      });
      props.onChange(currVal);

    } else {
      props.onChange(currVal);
    }

  }


  useEffectOnce(() => {
    if (props.focus) {
      inputRef.current.focus();
    }
  });

  return (
    <div className="textfield">
       <label
        className="textfield__label"
        htmlFor={props.id}>
        {props.label}
      </label>
      <div className="textfield__input-wrapper">

        <details className={classList(classMap)} >
            <summary className='custom-dropdown__selected'>
              {
                (selectedImage && selectedImage !== null) &&
                <img src={selectedImage} alt={""} />

              }
              <span>{selectedValue.label}</span>
            </summary>
            <div className='custom-dropdown__options'>
            {
                props.options.map((opt, index) => {
                  var optionValue = opt.value.toString().replace(" ", "");
                  return <div key={"country_"+index} className={"custom-dropdown__options__item " + (selectedValue.value === opt.value ? "isSelected" : "")}>
                      
                      <input 
                        className="sr-only"
                        type="radio" 
                        id={props.id ? props.id +"_"+optionValue : "selectedValue_"+optionValue} 
                        name={props.id ? props.id : "selectedValue"}
                        onChange={onChange}
                        title={props.label}
                        value={opt.value}
                        tabIndex={index}
                      />
                      <label htmlFor={props.id ? props.id +"_"+optionValue : "selectedValue_"+optionValue}   >
                      {
                          (opt.image && opt.image !== "") &&
                          <img src={opt.image} alt={opt.imageAlt} />
                      }
                        <span>{opt.label}</span>
                      </label>
                  </div>
                })
            }
        </div>
        
          
        </details>
        <span class="textfield__focus"></span>
      </div>
    </div>
  );
}
