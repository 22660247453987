import React from 'react';
import classList from 'react-classlist-helper';
import './Loader.scss';

export default (props) => {
  const classMap = {
    "loader": true,
    "loader--complete": props.complete,
    [props.className]: !!props.className,
  };

  return (
    <div className={classList(classMap)}>
      <div className="loader__checkmark loader__checkmark--draw"></div>
    </div>
  )
}