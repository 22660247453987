import React from 'react';
import Link from 'components/Link/Link';
import useSettingsContext from 'contexts/useSettingsContext';
import { ReactComponent as FbSvg } from 'assets/images/facebook.svg';
import { ReactComponent as InSvg } from 'assets/images/instagram.svg';
import { ReactComponent as TwSvg } from 'assets/images/twitter.svg';
import { ReactComponent as TikTSvg } from 'assets/images/tiktok.svg';
import { ReactComponent as MdlzSvg } from 'assets/images/mondelez-logo.svg';
import './Footer.scss';

import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';

export default function (props) {
    var settings = useSettingsContext();

    return (
      <footer className="footer">
        <div className="bg-black">
          {!props.basicFooter && 
          <SummaryTsandCs />
          }
        </div>
        <div className={props.basicFooter ? "footer__container footer__container--basic" : "footer__container"}>

          <div className="footer__menu">
            <div className="footer__menu__links">
              <div className="footer__menu__links__control">
                { props.showTerms &&
                  <Link 
                  analytics={{evnt: "siteFooterLink", category: "TermsAndConditionsLink", action: "OpenTermsAndConditions"}}
                  href="/terms-and-conditions"
                  target="_blank"
                  title="Terms and Conditions" >T&Cs</Link>
                }
                <Link 
                  analytics={{evnt: "siteFooterLink", category: "PrivacyPolicyLink", action: "OpenPrivacyPolicy"}}
                  href="https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/"
                  target="_blank"
                  title="Privacy Policy">Privacy Policy</Link>
               
                <Link 
                analytics={{evnt: "siteFooterLink", category: "CookiePolicyLink", action: "OpenCookiePolicy"}}
                href="/cookie-policy"
                target="_blank"
                title="Cookie Policy" >Cookie Policy</Link>
             
                <Link 
                  analytics={{evnt: "siteFooterLink", category: "ContactUsLink", action: "OpenContactUs"}}
                  href="https://contactus.mdlzapps.com/sweetsoundsofsummer.com/contact/en-UK/"
                  target="_blank"
                  title="Contact Us" >Contact Us</Link>
              </div>
              <div className="footer__copyright__text-container">
                <p className="footer__copyright__text">&copy; {new Date().getFullYear()} Mondelez UK &amp; Ireland - All Rights Reserved.</p>
                <p className="footer__copyright__text">&copy; {new Date().getFullYear()} Warner Bros. Ent. All Rights Reserved.</p>
              </div>
            </div>
          </div>


          <div className="footer__socials">
          <nav className="footer__menu__social-links">
              <Link 
                  analytics={{evnt: "socialLinks", category: "FacebookLink", action: "OpenFacebookLink"}}
                  href="https://www.facebook.com/MaynardsBassetts/"
                  target="_blank"
                  title="Maynards Bassetts on Facebook" >
                    <FbSvg />
              </Link>
              <Link 
                  analytics={{evnt: "socialLinks", category: "InstagramLink", action: "OpenInstagramLink"}}
                  href="https://www.instagram.com/maynardsbassetts/"
                  target="_blank"
                  title="Maynards Bassetts on Instagram" >
                    <InSvg />
              </Link>
            </nav>
            <div className="link">
              <Link 
                      analytics={{evnt: "siteFooterLink", category: "MondelezInternationalLink", action: "OpenMondelezInternationalLink"}}
                      href="http://www.mondelezinternational.com/"
                      target="_blank"
                      title="Mondelez International"
                      className="footer__copyright__logo" >
                        <MdlzSvg />
              </Link>
            </div>
          </div>
        </div>

      </footer>
    );
}