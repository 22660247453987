import React from 'react';
import { useLocation } from 'react-router-dom';
import analyticsService from 'services/analyticsService';

/**
 * Logs a GA page view when the browser location changes.
 */
export default () => {
  let location = useLocation();

  React.useEffect(
    () => {
        analyticsService.logPageView(location.pathname);
    },
    [location]
  )
}