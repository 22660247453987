import api from './api';

function addPrizeClaim(data) {
  return api
    .post('prize-claims', data);
}

function getPrizeClaim(token) {
  return api
        .get(`/prize-claims/${token}`);
}

export default {
  addPrizeClaim,
  getPrizeClaim
};