import React, { useEffect, useState } from 'react';
import TextFieldCharRestricted from './TextFieldCharRestricted';

import './DateInput.scss';

export default (props) => {

  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  
  const [readOnly, setReadOnly] = useState(false);
  

  const isValidDate = (year, month, day) => {
    month = month-1;
    var d = new Date(year, month, day);
    if (d.getFullYear() === parseInt(year) && d.getMonth() === parseInt(month) && d.getDate() === parseInt(day)) {
        return true;
    }
    return false;
  }

  const getDay = (date) => {
    if(date.length > 0){
      var newDate = new Date(date).getDate();
      var returnDay = (newDate.toString().length < 2 ? "0"+newDate : newDate);
      return returnDay;
    } else {
      return "";
    }
    
  }

  const getMonth = (date) => {
    if(date.length > 0){
      
      var newDate = new Date(date).getMonth();
      var returnMonth = (newDate.toString().length < 2 && newDate !== 9 ? "0"+(newDate+1) : (newDate+1));
      return returnMonth;
    } else {
      return "";
    }
    
  }

  const getYear = (date) => {
    if(date.length > 0){
      var newDate = new Date(date).getFullYear();
      return newDate;
    } else {
      return "";
    }
    
  }

  const parseDayAndMonth = val => {
    if (!val) return val;
    return val.toString().length === 1 ? `0${val}` : val
  }

  function validateResponse(day, month, year) {
    let today = new Date();
    let maxDate = new Date();
    maxDate.setYear(maxDate.getFullYear() - 120);
    let inputDate = new Date(`${year}-${parseDayAndMonth(month)}-${parseDayAndMonth(day)}T00:00:01Z`);

    let response = {};
    if (!day && !month && !year) {      
      response = {
        isValid: true,
        date: inputDate
      };
    }else{
      if (day && month && year && ((year + "").length !== 4) && inputDate) {
      
        response = {
          isValid: false,
          error: "Please enter a valid date 1"
        };
      } else if (!isValidDate(year, month, day)) {

        response = {
          isValid: false,
          error: "Please enter a valid date 2"
        };
      } else if (day && month && year && inputDate) {

        if (inputDate === "Invalid Date") {
          response = {
            isValid: false,
            error: "Please enter a valid date 3"
          };
        } else {

          if (props.minDate && (inputDate >= props.minDate && inputDate < today)) {
            response = {
              isValid: false,
              error: props.minDateErrorMessage || "Min Date"
            };
          } else if(inputDate < maxDate) {
            response = {
              isValid: false,
              error: "Please enter a valid date of birth"
            };
          }
          else {

            if ( inputDate > today) {
              response = {
                isValid: false,
                error: "Please enter a valid date of birth"
              };
            } else {

              response = {
                isValid: true,
                date: inputDate
              };
            }

            
          }
        }
      } else {
        response = {
          isValid: false
        };
      }
    }

    props.onChange(response);

  }

  useEffect(() => {
    var readonlyDay = "";
    var readonlyMonth = "";
    var readonlyYear = "";

    if(props.value !== null && props.value.date !== undefined)
    {

      readonlyDay = props.value.date.getDate();
      readonlyMonth = props.value.date.getMonth();
      readonlyYear =props.value.date.getFullYear();

        setDay(parseDayAndMonth(readonlyDay));   
        setMonth(parseDayAndMonth(readonlyMonth+1));  
        setYear(readonlyYear);  

        validateResponse(readonlyDay, readonlyMonth, readonlyYear);
    }

    setReadOnly(props.readonly);

  }, [ props.readonly]);

  useEffect(() => {
    let today = new Date();
    let maxDate = new Date();
    maxDate.setYear(maxDate.getFullYear() - 120);
    let inputDate = new Date(`${year}-${parseDayAndMonth(month)}-${parseDayAndMonth(day)}T00:00:01Z`);

    let response = {};
    if (!day && !month && !year) {      
      response = {
        isValid: true,
        date: inputDate
      };
    }else{
      if (day && month && year && ((year + "").length !== 4) && inputDate) {
      
        response = {
          isValid: false,
          error: "Please enter a valid date"
        };
      } else if (!isValidDate(year, month, day)) {

        response = {
          isValid: false,
          error: "Please enter a valid date"
        };
      } else if (day && month && year && inputDate) {

        if (inputDate === "Invalid Date") {
          response = {
            isValid: false,
            error: "Please enter a valid date"
          };
        } else {

          if (props.minDate && (inputDate >= props.minDate && inputDate < today)) {
            response = {
              isValid: false,
              error: props.minDateErrorMessage || "Min Date"
            };
          } else if(inputDate < maxDate) {
            response = {
              isValid: false,
              error: "Please enter a valid date of birth"
            };
          } else {
            if ( inputDate > today) {
              response = {
                isValid: false,
                error: "Please enter a valid date of birth"
              };
            } else {

              response = {
                isValid: true,
                date: inputDate
              };
            }
          }
        }
      } else {
        response = {
          isValid: false
        };
      }
    }

    props.onChange(response);

  }, [day, month, year]);

  return <>

    <div className="date-input">
      <TextFieldCharRestricted 
        label="Day"
        id="day"
        autoComplete="bday-day"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setDay}
        placeholder="DD"
        value={day}
	      readonly={readOnly}
        maxCharLenght ={2}
        //disabled={readOnly}
      />
      <TextFieldCharRestricted 
        label="Month"
        id="month"
        autoComplete="bday-month"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setMonth}
        placeholder="MM"
        value={month}
	      readonly={readOnly}
        maxCharLenght ={2}
      //  disabled={readOnly}
      />
      <TextFieldCharRestricted 
        label="Year"
        id="year"
        autoComplete="bday-year"
        pattern="[0-9]*"
        inputmode="numeric"
        type="number"
        onChange={setYear}
        placeholder="YYYY"
        value={year}
        readonly={readOnly}
        maxCharLenght ={4}
       // disabled={readOnly}
      />
    </div>
  </>
}